import React, { useState, useEffect, useRef, Component } from 'react';
import axios from 'axios';
import { LoadScript, Autocomplete, GoogleComponent, GoogleMap, Circle, Marker } from '@react-google-maps/api';
import { BrowserRouter as Router, Routes, Route, Link, useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Col, InputGroup, FormControl, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import WEEKARRAY_CONST from '../../json/availability.json';
import SALARY_TYPE_JSON from '../../json/salary_type.json';
import { useSelector, useDispatch } from 'react-redux'
import { recdeshjobactioncheck } from '../../redux_state/reducres/findStaffBtnAction';
import customStyles from '../../expocomp/SelectStyleComp';
import JobTitleCAtegoryComp from '../../expocomp/JobTitleCAtegoryComp';

const GAPI = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const libraries = ['places']; // Define libraries array outside the component



const FindStaffModel = (prop) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fsmodelaction_switch = useSelector((state) => state.fsmodelaction.value);
    const jobidarray_switch = useSelector((state) => state.fsmodelaction.jobidarray);

    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
    //const customStyles = { option: (provided, state) => ({ ...provided, padding: '2px 10px', fontSize: '14px' }), };
    //console.log(sessionStorageData);
    var get_all_qualifications_options = [], get_all_eduqualification = [], get_all_jobtitle = [], get_all_jobtype_options = [], get_all_jobtype = [], get_all_language = [], get_all_industry = [];
    let dddd = (localStorage.getItem("sessionStorageData"))

    if (typeof (sessionStorageData) != 'undefined' && dddd != null && typeof (dddd) != 'undefined') {

        get_all_eduqualification = (sessionStorageData?.get_all_eduqualification ? sessionStorageData.get_all_eduqualification : []);
        get_all_jobtitle = (sessionStorageData?.get_jobtitle_options ? sessionStorageData.get_jobtitle_options : []);
        get_all_jobtype = (sessionStorageData?.get_all_jobtype ? sessionStorageData.get_all_jobtype : []);
        get_all_language = (sessionStorageData?.get_all_language ? sessionStorageData.get_all_language : []);
        get_all_industry = (sessionStorageData?.get_all_industry_options ? sessionStorageData.get_all_industry_options : []);
        get_all_jobtype_options = (sessionStorageData?.get_all_jobtype_options ? sessionStorageData?.get_all_jobtype_options : []);
        get_all_qualifications_options = (sessionStorageData?.get_all_qualifications_options ? sessionStorageData?.get_all_qualifications_options : []);
    }


    const [hideAndSeekSection, setHideAndSeekSection] = useState(false);
    const [isLoadingManualBtn, setIsLoadingManualBtn] = useState(false);
    const [findstaffEditID, setFindstaffEditID] = useState(0);
    const [screenTypeCall, setScreenTypeCall] = useState('');
    const [screenType, setScreenType] = useState('');
    const [isDataLoading, setIsDataLoading] = useState(false);


    const [step, setStep] = useState(1);
    // const setStep0 = () => { setStep(10) };
    const setStep1 = () => { setStep(1) };
    const setStep2 = () => { setStep(2) };
    const setStep3 = () => { setStep(3) };
    const setStep4 = () => { setStep(4) };
    const setStep5 = () => { setStep(5) };
    const setStep6 = () => { setStep(6) };
    const setStep7 = () => { setStep(7) };
    const setStep8 = () => { setStep(8) };
    const setStep9 = () => { setStep(9) };
    const setStep10 = () => { setStep(10) };


    const getfindstaffJobData = (jobofferid, actiontype, screentype, searchtype = 'manually') => {
        setScreenTypeCall(actiontype);
        setScreenType(screentype);
        if (searchtype == 'quick_search') {
            if (actiontype == 'preview' || actiontype == 'repost') { setStep(11) } else { setStep(6) }
        } else {
            if (actiontype == 'preview' || actiontype == 'repost') { setStep(5) } else { setStep(2) }
        }


        if (jobofferid) {
            (async () => {
                try {
                    setIsDataLoading(true);
                    const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_offer_job_detail/" + useriddata.login_token + "/" + jobofferid);
                    if (resData.data.status == 1) {
                        if (resData.data.data.id) {
                            if (screentype != 'completed_job') { setFindstaffEditID(jobofferid); }
                            if (actiontype == 'repost') { setFindstaffEditID(0); }
                            setIsDataLoading(false);

                            let getdata = resData.data.data;
                            if (searchtype == 'quick_search') {
                                setQuickFormEditId(jobofferid);

                                setquickFormData2({
                                    'job_title': getdata.job_title,
                                    'industry': getdata.industry,
                                    'total_experience_year': getdata.total_experience_year,
                                    'total_experience_month': getdata.total_experience_month,
                                    'staff_number': getdata.staff_number,
                                    'salary_type': getdata.salary_type,
                                    'salary_minimum': getdata.salary_minimum,
                                    'salary_maximum': getdata.salary_maximum,
                                    'fixed_salary': getdata.fixed_salary,
                                    'latitude': getdata.latitude,
                                    'longitude': getdata.longitude,
                                    'work_location': getdata.job_location,
                                    'range_from_location': getdata.range_from_location,
                                    'expire_date': getdata.expire_date,
                                    'expire_date_text': getdata.expire_date_text,
                                    'salary_you_are_offering': getdata.fixed_salary,
                                    'job_descriptions': getdata.job_descriptions,
                                    'requirement': getdata.requirement,
                                    'required_tax_type': getdata.required_tax_type,
                                    'availability': JSON.parse(getdata.availibility),
                                });
                                setWeekNameArray(JSON.parse(getdata.availibility));
                                //setTimeout(function(){ quickFormDataSubmitFun(2);setShouldRunEffect(true);  } , 2000)
                            } else {
                                setManuallyFormData2({
                                    'job_title': getdata.job_title,
                                    'job_type': getdata.job_type,
                                    'job_location': getdata.job_location,
                                    'range_from_location': getdata.range_from_location,
                                    'staff_number': getdata.staff_number,
                                    'total_experience_year': getdata.total_experience_year,
                                    'total_experience_month': getdata.total_experience_month,
                                    'salary_type': getdata.salary_type,
                                    'salary_minimum': getdata.salary_minimum,
                                    'salary_maximum': getdata.salary_maximum,
                                    'fixed_salary': getdata.fixed_salary,
                                    'are_you_paying_extra_in_holiday': getdata.are_you_paying_extra_in_holiday,
                                    'are_you_paying_extra_in_weekend': getdata.are_you_paying_extra_in_weekend,
                                    'are_you_paying_extra_in_overtime': getdata.are_you_paying_extra_in_overtime,
                                    'extrapay_overtime': getdata.extrapay_overtime,
                                    'extrapay_allowances': getdata.extrapay_allowances,
                                    'extrapay_bonuses': getdata.extrapay_bonuses,
                                    'required_education': JSON.parse(getdata.required_education),
                                    'extra_qualification': JSON.parse(getdata.extra_qualification),
                                    'language': JSON.parse(getdata.language),
                                    'expire_date': getdata.expire_date,
                                    'expire_date_text': getdata.expire_date_text,
                                    'job_descriptions': getdata.job_descriptions,
                                    'required_tax_type': getdata.required_tax_type,
                                    'i_am_fresher': getdata.i_am_fresher,
                                    'availability': JSON.parse(getdata.availibility),
                                });
                                //setWeekNameArrayManual(JSON.parse(getdata.availibility));
                            }
                            //console.log(JSON.parse(resData.data.data.availibility));
                            isSetfindstaffModalShow(true);

                        }
                    }
                } catch (error) { console.log(error); }
            })();
        }
    }

    const [isfindstaffModalShow, isSetfindstaffModalShow] = useState(false);
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const jobofferid = queryParameters.get("jobofferid");
        const actiontype = queryParameters.get("screencall");
        const screentype = queryParameters.get("type");
        const searchtype = queryParameters.get("searchtype");
        if (jobofferid) { console.log('....useEffect2.....'); getfindstaffJobData(jobofferid, actiontype, screentype, searchtype) };
    }, []);


    useEffect(() => {
        if (jobidarray_switch.id) {

            if (jobidarray_switch.id == 'new') {
                console.log('....useEffect1.....');
                setFindstaffEditID(0);
                setStep1();
                // dispatch(fsmodelhideandseek('new'))
                isSetfindstaffModalShow(true);
                setIsDataLoading(false);
            } else if (jobidarray_switch.id == 'close') {
                // dispatch(fsmodelhideandseek('close'))
                isSetfindstaffModalShow(false);
                //setIsDataLoading(true);
            } else if (jobidarray_switch.id != 'new') {
                //console.log('....useEffect3.....');
                //console.log(jobidarray_switch);
                //console.log(jobidarray_switch.actiontype);
                const jobofferid = jobidarray_switch.id;
                const actiontype = jobidarray_switch.actiontype;
                const screentype = jobidarray_switch.screentype;
                const searchtype = jobidarray_switch.searchtype;

                getfindstaffJobData(jobofferid, actiontype, screentype, searchtype);
            }

        }
    }, [jobidarray_switch.randome_number, jobidarray_switch.id, jobidarray_switch.actiontype, jobidarray_switch.screentype]);


    //const setHideAndSeekSectionFun = () => { setHideAndSeekSection(!hideAndSeekSection); }


    const FormatCurrency = (attr) => {
        // Add thousands separator
        //const formattedNumber = attr.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        const formattedNumber = parseFloat(attr.price);
        // Format the number as a currency string
        return `$${formattedNumber}.00`;
    };

    const YearExpNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Years</option>); for (let i = 0; i < 50; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i} {(i == 1 || i == 0) ? 'Year' : 'Years'}</option>); } return itemElements }
    const MonthExpNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Month</option>); for (let i = 0; i < 12; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i} Month{(i == 1 || i == 0) ? '' : 's'}</option>); } return itemElements }



    const sessionSotage = JSON.parse(localStorage.getItem("sessionStorageData"));
    const get_available_coins = sessionSotage.get_available_coins ? sessionSotage.get_available_coins : 0;
    const get_porduct_details = sessionSotage.get_porduct_details ? sessionSotage.get_porduct_details : 0;

    const quichsearch_per_match = get_porduct_details.quichsearch_per_match ? parseInt(get_porduct_details.quichsearch_per_match) : 5;
    const manualsearch_charge = get_porduct_details.manualsearch_charge ? parseInt(get_porduct_details.manualsearch_charge) : 2;

    // /console.log(get_available_coins);


    const [selectedDate, setSelectedDate] = useState(null);
    const [confirmedDate, setConfirmedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleConfirm = () => {
        setConfirmedDate(selectedDate);
        alert(`Date confirmed: ${selectedDate.toLocaleDateString()}`);
    };

    const handleCancel = () => {
        setSelectedDate(null);
    };
    const Findstaff_form_1 = () => {
        return (
            <div className="stafformwrap commonform stepscount">
                <div className='row'>
                    <div className="col-md-12">
                        <div className="form-group text-center mb-3"><h3>Which type of search you want to make?</h3></div>
                        <div className="row">
                            <div className="col-md-6 d-flex">
                                {
                                    (get_available_coins >= manualsearch_charge) ?
                                        <>
                                            <Link onClick={(e) => setStep(2)} disabled={isLoadingManualBtn} className="batn batn-orange w-100">
                                                Manual Search
                                            </Link>
                                            <span className="inforpop">
                                                <span className="infoicon"><img src="/front/images/infoicoin.png" /></span>
                                                <span className="infodtl">
                                                    <h4>Mannual Search</h4>
                                                    <p>Enables users to find job listings by entering specific keywords or criteria on the job portal.</p>
                                                </span>
                                            </span>
                                        </>
                                        :
                                        <>
                                            <span onClick={(e) => setStep('error')} class="batn batn-orange w-100" href="/recruiter/dashboard">Manual Search</span>
                                            <span className="inforpop">
                                                <span className="infoicon"><i class="fa fa-warning search_warning_icon"></i></span>
                                                <span className="infodtl">
                                                    <h4>Mannual Search</h4>
                                                    <p>Instantly displays relevant job listings as users type, streamlining the job search process on the portal.</p>
                                                </span>
                                            </span>
                                        </>
                                }
                            </div>
                            <div className="col-md-6 d-flex">
                                {
                                    (get_available_coins >= quichsearch_per_match) ?
                                        <>
                                            <Link onClick={(e) => setStep(6)} disabled={isLoadingManualBtn} className="batn batn-purple w-100">
                                                Quick Search
                                            </Link>
                                            <span className="inforpop">
                                                <span className="infoicon"><img src="/front/images/infoicoin.png" /></span>
                                                <span className="infodtl">
                                                    <h4>Quick Search {quichsearch_per_match}</h4>
                                                    <p>Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Cras dapibus. Nam commodo suscipit quam.</p>
                                                </span>
                                            </span>
                                        </>
                                        :
                                        <>
                                            <span onClick={(e) => setStep('error')} class="batn batn-purple w-100" href="/recruiter/dashboard">Quick Search</span>
                                            <span className="inforpop">
                                                <span className="infoicon"><i class="fa fa-warning search_warning_icon"></i></span>
                                                <span className="infodtl">
                                                    <h4>Quick Search</h4>
                                                    <p>Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Cras dapibus. Nam commodo suscipit quam.</p>
                                                </span>
                                            </span>
                                        </>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    /*----------------------------------"manually search steps "-----------------------------------------------*/
    const [jobTypeList, setJobTypeList] = useState([]);
    const [jobTypeListOptions, setJobTypeListOptions] = useState([]);

    const [allLanguageListOption, setAllLanguageListOption] = useState([]);
    const [allEducationListOptions, setAllEducationListOptions] = useState([]);
    const [allQualificationListOptions, setAllQualificationListOptions] = useState([]);
    const [allQualificationList, setAllQualificationList] = useState([]);
    //const [allLanguageList, setAllLanguageList] = useState(manuallyFormData2.language);
    const [jobTitleList, setJobtitleList] = useState([]);

    useEffect(() => {
        (async () => {
            if (get_all_jobtype.length > 0) {
                setAllLanguageListOption(get_all_language.option);
                setAllEducationListOptions(get_all_eduqualification.option);
                setJobtitleList(get_all_jobtitle);
                setJobTypeList(get_all_jobtype);
                setJobTypeListOptions(get_all_jobtype_options);
                setAllQualificationListOptions(get_all_qualifications_options)
            } else {
                try {
                    const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/localstorage_common_data?calling_by_api");
                    if (resData.data.status == 1) {
                        const resArry = resData.data.data;
                        setAllEducationListOptions(resArry.get_all_eduqualification.option);
                        setAllQualificationListOptions(resArry.get_all_qualifications_options);
                        setAllLanguageListOption(resArry.get_all_language);
                        setJobtitleList(resArry.get_jobtitle_options);
                        setJobTypeList(resArry.get_all_jobtype);
                        setJobTypeListOptions(resArry.get_all_jobtype_options);
                    }
                } catch (error) { console.log(error); }
            }
        })();
    }, []);



    const [manuallyFormData2, setManuallyFormData2] = useState({
        'job_title': '',
        'job_type': '',
        'job_location': '',
        'range_from_location': '',
        'latitude': -33.8688197,
        'longitude': 151.2092955,
        'staff_number': '',
        'total_experience_year': '',
        'total_experience_month': '',
        'availability': WEEKARRAY_CONST,
        'salary_type': 'hourly',
        'salary_minimum': '',
        'salary_maximum': '',
        'fixed_salary': '',
        'are_you_paying_extra_in_holiday': 'yes',
        'are_you_paying_extra_in_weekend': 'yes',
        'are_you_paying_extra_in_overtime': 'yes',
        'extrapay_overtime': 'yes',
        'extrapay_allowances': 'yes',
        'extrapay_bonuses': 'yes',
        'required_education': [],
        'extra_qualification': [],
        'language': [{ value: '1', label: 'English' }],
        'address': [],
        'expire_date': '',
        'job_descriptions': '',
        'required_tax_type': 'abn',
        'i_am_fresher': false,
    });
    const [manualShouldRunEffect, setManualShouldRunEffect] = useState(false);

    const manuallyFormDataSubmitFun = (finalStepSubmit = 0) => {
        console.log('calling...');
        console.log(finalStepSubmit);
        if (manuallyFormData2.job_title) {
            console.log('calling...=' + finalStepSubmit);

            const formData = new FormData();
            formData.append('user_unique_id', useriddata.unique_id);
            formData.append('edit_id', quickFormEditId);
            formData.append('action_type', jobidarray_switch.actiontype);
            formData.append('screen_type', jobidarray_switch.screentype);
            formData.append('status', ((finalStepSubmit == 1) ? 'active' : 'draft'));
            formData.append('formdata', JSON.stringify(manuallyFormData2));

            try {
                axios.post('https://squadgoo.com.au/api.squadgoo.com.au/action_manuallsearch_action', (formData))
                    .then((response) => {
                        if (response.status == 200 && response.data.status == 1) {
                            setQuickFormEditId(response.data.data.edit_id);
                            setManualShouldRunEffect(false);
                            console.log(finalStepSubmit)
                            if (finalStepSubmit == 1) {
                                setTimeout(window.location.replace("/manual-search?formid=" + response.data.data.edit_id), 1000);
                            }
                        }
                    }).catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }

        }
    }
    useEffect(() => { manuallyFormDataSubmitFun(0); }, [manuallyFormData2]);


    const autocompleteRef = useRef(null);

    const Findstaff_form_2 = () => {

        //const [locationInfo, setLocationInfo] = useState(null);
        const [stepData, setStepData] = useState(manuallyFormData2);
        const stepChangeFormFun = (event) => {
            const { name, value } = event.target;
            setStepData((prevFormData) => ({ ...prevFormData, [name]: value }));
        }

        const [validated, setValidated] = useState(false);
        const onFinalSubmit = (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
            else {
                setManuallyFormData2(stepData);
                setStep(3);
                setManualShouldRunEffect(true);
            }
            setValidated(true);
        };

        /*add new title functions */
        const [isAddNewJobModalShow, setIsAddNewJobModalShow] = useState(false);
        const [isAddNewJobtitleError, setIsAddNewJobtitleError] = useState(false);
        const [getNewTitle, setgetNewTitle] = useState('');
        const [isAddNewJobtitleErrorMessage, setIsAddNewJobtitleErrorMessage] = useState('');
        //const [lastInsertJobId, setLastInsertJobId] = useState('');
        const joBTitleSelected = (select) => {
            setIsAddNewJobModalShow(false);
            console.log(select.value)
            if (select.value === 'other') { setIsAddNewJobModalShow(true) }
            else {
                setStepData((prevFormData) => ({
                    ...prevFormData,
                    ['job_title']: select.value
                }));
            }
        }

        const joBTypeSelected = (select) => { setStepData((prevFormData) => ({ ...prevFormData, ['job_type']: select.value })); }

        const addJobTitleInListfun = (event) => {
            event.preventDefault();
            let value = getNewTitle;
            if (value && value.length > 3) {
                setIsAddNewJobtitleError(false);
                (async () => {
                    try {
                        const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/set_new_jobtitle/${useriddata.unique_id}/${value}`);
                        if (resData.data.status == 1) {
                            setJobtitleList(resData.data.data.jobtitle_option);
                            //setLastInsertJobId(resData.data.data.last_id);
                            setIsAddNewJobtitleErrorMessage(resData.data.message);
                            setStepData((prevFormData) => ({
                                ...prevFormData,
                                ['job_title']: resData.data.data.last_id
                            }));


                        } else { setIsAddNewJobtitleErrorMessage(resData.data.message) }
                    } catch (error) { console.log(error); }
                })();
            } else {
                setIsAddNewJobtitleError(true);
                setIsAddNewJobtitleErrorMessage('Please enter title');
            }
        }



        /*---------------------------------*/
        const [radius, setRadius] = useState(5000); // Default radius in meters
        const [rangeInput, setRangeInput] = useState(5); // Default range in kilometers
        const [center, setMapCenter] = useState({ lat: stepData.latitude, lng: stepData.longitude });
        //const [radius, setRadius] = useState(10000);
        const [zoomLevel, setZoomLevel] = useState(10);



        const handleRangeChange = (event) => {
            const newRange = parseFloat(event.target.value);
            setRadius(newRange * 1000); // Convert kilometers to meters
            setRangeInput(newRange);
            setStepData((prevFormData) => ({ ...prevFormData, ['range_from_location']: newRange }));
            setZoomLevel(calculateZoomLevel(newRange * 1000));
        };

        useEffect(() => { setZoomLevel(calculateZoomLevel(rangeInput * 1000)); }, [rangeInput]);

        const handlePlaceSelect = async () => {
            const place = autocompleteRef.current.getPlace();
            console.log('Place:', place);

            if (!place.geometry) {
                console.error("No details available for input: ", place);
                return;
            }

            try {
                const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${place.place_id}&key=${GAPI}&libraries=places&v=weekly&callback=initMap&loading=async`);

                console.log(response)
                if (response.data.results.length > 0) {
                    const { lat, lng } = response.data.results[0].geometry.location;
                    const formattedAddress = response.data.results[0].formatted_address;

                    setStepData((prevFormData) => ({
                        ...prevFormData,
                        ['address']: { lat, lng, formattedAddress }
                    }));

                    setStepData((prevFormData) => ({
                        ...prevFormData,
                        ['job_location']: formattedAddress
                    }));

                    setMapCenter({ lat: lat, lng: lng });

                    //latitude
                    setStepData((prevFormData) => ({ ...prevFormData, ['latitude']: lat }));
                    setStepData((prevFormData) => ({ ...prevFormData, ['longitude']: lng }));

                } else {
                    console.error("No results found for the provided address.");
                }
            } catch (error) {
                console.error("Error fetching geocode data:", error);
            }
        };


        const calculateZoomLevel = (radius) => {
            if (radius > 1500 * 1000) { return 3; }
            else if (radius > 1000 * 1000) { return 4; }
            else if (radius > 750 * 1000) { return 5; }
            else if (radius > 300 * 1000) { return 6; }
            else if (radius > 100 * 1000) { return 7; }
            else if (radius > 70 * 1000) { return 8; }
            else if (radius > 30 * 1000) { return 9; }
            else { return 10; }
        };
        /*---------------------------------*/
        const handleJobTitleChange = (selectedJobTitle) => {

            setIsAddNewJobModalShow(false);
            console.log(selectedJobTitle.value)
            if (selectedJobTitle.value === 'other') { setIsAddNewJobModalShow(true) }
            else {
                setStepData((prevFormData) => ({
                    ...prevFormData,
                    ['job_title']: selectedJobTitle.value
                }));
            }

        };

        const [classForTitleError, setClassForTitleError] = useState('');
        const [classForCategoryError, setClassForCategoryError] = useState('');
        // setClassForCategoryError('');
        //         setClassForTitleError('');


        return (
            <div className="staffpop">
                <div className="staffbanner text-center">
                    <h3>
                        {jobidarray_switch.screentype == 'new_form' && <span className="text-white cursor_pointer" onClick={setStep1}><i className="fa fa-chevron-left" aria-hidden="true"></i></span>}
                        Find a staff
                    </h3>
                    <img src="/front/images/staffserach.png"></img>
                </div>
                <div className="stafformwrap commonform stepscount">
                    {/* <LoadScript googleMapsApiKey={GAPI} libraries={libraries}  > */}
                    <Form noValidate validated={validated} onSubmit={onFinalSubmit} >
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div className="form-group"><h3>Step 1 of 3</h3></div>
                            </div>

                            <JobTitleCAtegoryComp
                                defaultJobID={stepData.job_title}
                                onJobTitleChange={handleJobTitleChange}
                                classForCategory={classForCategoryError}
                                classForTitle={classForTitleError}
                            />
                            {/* <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Job Title<span className="starr">*</span></label>
                                    <Select
                                        className='react_select'
                                        options={jobTitleList}
                                        name="job_title"
                                        isSearchable
                                        value={jobTitleList.find(item => item.value === stepData.job_title)}
                                        onChange={joBTitleSelected}
                                        placeholder="Search..."
                                        style={customStyles}
                                    />
                                </div>
                            </div> */}
                            {isAddNewJobModalShow && <div className="col-md-12 mb-3">
                                <div className="input-group mb-2">
                                    <input type="text" required={true} onChange={(e) => setgetNewTitle(e.target.value)} value={getNewTitle} className="form-control" name="add_job_title" placeholder="Enter job title" />
                                    <div className="input-group-append"><button className="batn batn-orange  add_newjobtitle" onClick={addJobTitleInListfun}>Add Title</button></div>
                                    {
                                        isAddNewJobtitleErrorMessage && <><div className='error_message'>{isAddNewJobtitleErrorMessage}</div></>
                                    }
                                </div>
                            </div>}

                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Job type<span className="starr">*</span></label>
                                    {/* jobTypeListOptions */}

                                    <Select
                                        className='react_select'
                                        options={jobTypeListOptions}
                                        name="job_title"
                                        isSearchable
                                        value={jobTypeListOptions.find(item => item.value === stepData.job_type)}
                                        onChange={joBTypeSelected}
                                        placeholder="Search..."
                                        style={customStyles}
                                    />

                                    {/* <Form.Select name="job_type" onChange={stepChangeFormFun} required aria-label="Default select example">
                                        <option value=''>Select</option>
                                        {
                                            jobTypeList.map((item, index) => (
                                                <option key={item.id} selected={stepData.job_type == item.id ? true : false} value={item.id} datatitle={item.jobtype} >{item.jobtype}</option>
                                            ))
                                        }
                                    </Form.Select> */}
                                </div>
                            </div>

                            {/* ----------------------------------------------------- */}
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Work location<span className="starr">*</span></label>
                                    <div>
                                        <Autocomplete
                                            onLoad={(autocomplete) => {
                                                console.log('Autocomplete loaded:', autocomplete);
                                                autocompleteRef.current = autocomplete;
                                            }}
                                            onPlaceChanged={handlePlaceSelect}
                                        >
                                            <input
                                                type="text"
                                                placeholder="Enter a location"
                                                className='form-control'
                                                required={true} name="job_location" onChange={stepChangeFormFun} value={stepData.job_location}
                                            />
                                        </Autocomplete>
                                    </div>
                                    {/* <Form.Control onChange={stepChangeFormFun} required={true} name="job_location" type="text" value={stepData.job_location} className="form-control" placeholder="Add the location you are hiring for" /> */}
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Range from location (km)<span className="starr">*</span></label>
                                    <input value={rangeInput}
                                        onChange={handleRangeChange} required={true} name="range_from_location" min="1" max="2500" placeholder="Add distance from your location to hire" type="range" className="width-100percent" />
                                    {stepData.range_from_location && <span className="fieldsub">{stepData.range_from_location} km</span>}
                                    {/* {radius && <span className="fieldsub">{stepTwoData.range_from_location} km</span>} */}
                                </div>
                            </div>

                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <GoogleMap
                                        mapContainerStyle={{ height: "300px", width: "100%" }}
                                        zoom={zoomLevel}
                                        center={center}
                                    >
                                        {radius && (
                                            <Circle
                                                center={center}
                                                radius={parseFloat(radius)}
                                                options={{
                                                    strokeColor: "#000000",
                                                    strokeOpacity: 0.8,
                                                    strokeWeight: 2,
                                                    fillColor: "#000000",
                                                    fillOpacity: 0.35,
                                                    clickable: false,
                                                    draggable: false,
                                                    editable: false,
                                                    visible: true,
                                                }}
                                            />
                                        )}
                                        <Marker position={center} />
                                    </GoogleMap>
                                </div>
                            </div>
                            {/* ----------------------------------------------------- */}

                            <div className="col-md-12 mb-4">
                                <div className="form-group">
                                    <label className="form-label">How many staff looking for<span className="starr">*</span> </label>
                                    <Form.Control onChange={stepChangeFormFun} required={true} name="staff_number" type="number" value={stepData.staff_number} className="form-control" placeholder="Total staff numbers" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button type='submit' className="batn batn-orange w-100" >Next</button>
                            </div>

                        </div>
                    </Form>
                    {/* </LoadScript> */}
                </div>
            </div>
        )
    }

    const Findstaff_form_3 = () => {
        const inputRefs = useRef([]);
        const inputRefs2 = useRef([]);
        const [activeIndex, setActiveIndex] = useState(null);

        // console.log(finsStaffForm2DataArray)
        const [stepData, setStepData] = useState(manuallyFormData2);
        /*--section --*/
        const setHideAndSeekSectionFun = () => { setHideAndSeekSection(!hideAndSeekSection); }
        //console.log(stepData);
        /*-----Availibility  copy function ------*/
        const [weekNameArrayManual, setWeekNameArrayManual] = useState(stepData.availability ? stepData.availability : WEEKARRAY_CONST);

        const checkValueofDay = (key, type, value, index_key) => {
            const newArray = [...weekNameArrayManual];
            const index = newArray.findIndex(item => item.key === key);
            if (index !== -1) {
                if (type === 'from') { newArray[index].from_value = value; inputRefs2.current[index].focus(); }
                else if (type === 'to') { newArray[index].to_value = value; }
                else {
                    newArray[index].checked = value;
                    newArray[index].from_value = "";
                    newArray[index].to_value = "";
                    if (value == true && !newArray[index].from_value && !newArray[index].to_value) {
                        newArray[index].from_value = "00:00";
                        newArray[index].to_value = "23:59";
                        inputRefs.current[index].focus();
                        setActiveIndex(index);
                    }

                    // newArray[index].checked = value;
                    // if (value == false) {
                    //     newArray[index].from_value = '';
                    //     newArray[index].to_value = '';
                    // }
                    // newArray[index].checked = false;
                    // if (newArray[index].from_value && newArray[index].to_value) {
                    //     newArray[index].checked = true;
                    // }
                }

                // if (newArray[index].from_value && newArray[index].to_value) {
                //     newArray[index].checked = true;
                // }
                setWeekNameArrayManual(newArray);
                setStepData((prevFormData) => ({ ...prevFormData, ['availability']: newArray }));
            }
        }

        const copyFromAndToTime = (index, key) => {
            const newArray = [...weekNameArrayManual];
            const index_key = newArray.findIndex(item => item.key === key);

            if (index_key !== -1) {
                const newArray = weekNameArrayManual.map(obj => ({
                    ...obj,
                    ['from_value']: weekNameArrayManual[index_key].from_value,
                    ['to_value']: weekNameArrayManual[index_key].to_value,
                    ['checked']: true
                }));
                setWeekNameArrayManual(newArray);
                setStepData((prevFormData) => ({ ...prevFormData, ['availability']: newArray }));
            }
        }
        /*----------handleBlur value swaping --------*/

        const handleBlur = () => {
            const minSalary = stepData.salary_minimum;
            const maxSalary = stepData.salary_maximum;
            if (minSalary !== '' && maxSalary !== '' && parseInt(minSalary, 10) > parseInt(maxSalary, 10)) {
                // Swap values if minimum is greater than maximum
                const temp = minSalary;
                setStepData((prevFormData) => ({ ...prevFormData, ['salary_maximum']: temp }));
                setStepData((prevFormData) => ({ ...prevFormData, ['salary_minimum']: maxSalary }));
            }
        };
        /*----------checkbox value is updating--------*/
        const extraPaying2 = (name, value) => {
            setStepData((prevFormData) => ({ ...prevFormData, [name]: value }));
        }



        /*-----------------------------------------*/
        const stepChangeFormFun = (event) => {
            const { name, value, type, checked } = event.target;
            if (type === 'checkbox') {
                setStepData((prevFormData) => ({ ...prevFormData, [name]: checked }));
            } else {
                if (event.target.name == 'salary_maximum' && event.target.value.length > 6) {
                    return false;
                }
                if (event.target.name == 'salary_minimum' && event.target.value.length > 6) {
                    return false;
                }
                setStepData((prevFormData) => ({ ...prevFormData, [name]: value }));
            }

        }

        const [validated, setValidated] = useState(false);
        const onFinalSubmit = (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
            else {

                setManuallyFormData2(stepData);
                setManuallyFormData2((prevFormData) => ({ ...prevFormData, ['availability']: weekNameArrayManual }));
                setStep(4);
                setManualShouldRunEffect(true);
            }
            setValidated(true);
        };


        return (
            <div className="staffpop">

                <div className="staffbanner text-center">

                    <h3><span className='text-white cursor_pointer' onClick={setStep2} ><i className="fa fa-chevron-left" aria-hidden="true"></i></span>  Find a staff</h3>
                    <img src="/front/images/staffserach.png"></img>
                </div>
                <div className="stafformwrap commonform stepscount">
                    <Form noValidate validated={validated} onSubmit={onFinalSubmit} >
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div className="form-group"><h3>Step 2 of 3</h3></div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Total experience needed<span className="starr">*</span></label>
                                    <div className="row">
                                        {
                                            <>
                                                <div className="col-md-6">
                                                    <select required={true} onChange={stepChangeFormFun} name="total_experience_year" className="form-control">
                                                        <YearExpNumberLoop select_attr={stepData.total_experience_year} />
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <select required={true} onChange={stepChangeFormFun} name="total_experience_month" className="form-control">
                                                        <MonthExpNumberLoop select_attr={stepData.total_experience_month} />
                                                    </select>
                                                </div>
                                            </>
                                        }
                                        <div className="col-md-12 mt-2">
                                            <div className="commonchecbox d-flex">
                                                <span className="agrrimentbox">
                                                    <input type="checkbox" checked={stepData.i_am_fresher} id="i_am_fresher" onChange={stepChangeFormFun} name="i_am_fresher" />
                                                    {/* <input type="checkbox" id="i_am_fresher" name="i_am_fresher" /> */}
                                                    <span className="checkafter"></span>
                                                </span>
                                                <label className="checklabel f-w-medium" for="i_am_fresher" >Freshers can also apply</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group mb-3">
                                    <label className="form-label">Availability to work</label>
                                    <label className="smalllbl">Choose days and time you want seeker to be available</label>
                                    {
                                        weekNameArrayManual.map((item, index) => (
                                            <div className="availability-sec d-flex align-items-center  mb-2" key={item.name}>
                                                <div className="checkboxwraper">
                                                    <input type="checkbox" name={`availability[${item.key}][checkebox][]`} onChange={(e) => checkValueofDay(item.key, 'checkbox', e.target.checked, index)} value={item.from_value} checked={item.checked} ></input>
                                                    <span className="checkspan"></span>
                                                </div>
                                                <p>{item.name}</p>
                                                <input type="time" name={`availability[${item.key}][from][]`} onChange={(e) => checkValueofDay(item.key, 'from', e.target.value, index)} value={item.from_value} ref={(el) => (inputRefs.current[index] = el)} className={`form-control ${activeIndex === index ? 'availibility_active_from' : ''}`} placeholder="00:00 am" ></input>
                                                <div className="subheadpera">To</div>
                                                <input type="time" name={`availability[${item.key}][to][]`} onChange={(e) => checkValueofDay(item.key, 'to', e.target.value, index)} value={item.to_value} ref={(el) => (inputRefs2.current[index] = el)} className={`form-control ${activeIndex === index ? 'availibility_active_from' : ''}`} placeholder="00:00 am"></input>
                                                {
                                                    (item.from_value && item.to_value) ?
                                                        <>
                                                            <span className="copyin ml-3 cursor_pointer infowrap" onClick={(e) => copyFromAndToTime(index, item.key)}><img src="/front/images/copuicon.png" /><span className="onhoverinfo">Duplicate the same time for week availability</span></span>
                                                        </>
                                                        : ''
                                                }
                                            </div>
                                        ))
                                    }


                                </div>
                            </div>


                            <div className="col-md-12">
                                <div className="form-group mb-3">
                                    <label className="form-label">Salary type</label>
                                    <select required={true} onChange={stepChangeFormFun} name="salary_type" className="form-control">
                                        {
                                            SALARY_TYPE_JSON.map((item, key) => (
                                                <><option selected={stepData.salary_type == item.key} key={item.key} value={item.key}>{item.value}</option></>
                                            ))
                                        }
                                        {/* <option key={'hourly'} value={'hourly'}>Hourly</option>
                                        <option key={'fixed'} selected={stepData.salary_type == 'fixed'} value={'fixed'}>Fixed</option> */}
                                    </select>
                                </div>
                            </div>
                            {stepData.salary_type == 'hourly' ? <div className="col-md-12 mb-0">
                                <div className="form-group">
                                    <label className="form-label">Salary you are offering<span className="starr">*</span></label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="input-group mb-2 salaryfield2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input required={true} type="number" name="salary_minimum" onChange={stepChangeFormFun} onBlur={handleBlur} value={stepData.salary_minimum} maxlength="5" className="form-control" placeholder="Minimum" />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mt-2 text-center">To</div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="input-group mb-2 salaryfield2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input required={true} type="number" name="salary_maximum" onChange={stepChangeFormFun} onBlur={handleBlur} value={stepData.salary_maximum} maxlength="5" className="form-control" placeholder="Maximum" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                :
                                <div className="col-md-12 mb-3">
                                    <div className="form-group">
                                        <label className="form-label">Fixed salary you are offering ?</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="input-group mb-2 salaryfield2">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">$</div>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        onChange={stepChangeFormFun}
                                                        name="fixed_salary"
                                                        value={stepData.fixed_salary}
                                                        maxlength="3"
                                                        className="form-control"
                                                        placeholder="Fixed salary you are offering"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            }


                            <div className="col-md-12 mb-3 extra-paying">
                                <div className="form-group row">
                                    <label className="form-label">Extra pay</label>



                                    <div className="col-md-6">
                                        <div className="d-flex mb-2 align-items-center justify-content-betweenm">
                                            <div>Public holidays</div>
                                            <div className="switch-field mr-0">
                                                <div className="switchfieldinner">
                                                    <input checked={stepData.are_you_paying_extra_in_holiday == 'yes' ? true : false} onClick={(e) => extraPaying2('are_you_paying_extra_in_holiday', 'yes')} type="radio" id="accept" name="are_you_paying_extra_in_holiday" />
                                                    <label htmlFor="accept">Yes</label>
                                                </div>
                                                <div className="switchfieldinner">
                                                    <input checked={stepData.are_you_paying_extra_in_holiday == 'no' ? true : false} onClick={(e) => extraPaying2('are_you_paying_extra_in_holiday', 'no')} type="radio" id="completed" name="are_you_paying_extra_in_holiday" />
                                                    <label htmlFor="completed">No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-2 align-items-center justify-content-betweenm">
                                            <div>Weekend</div>
                                            <div className="switch-field mr-0">
                                                <div className="switchfieldinner">
                                                    <input checked={stepData.are_you_paying_extra_in_weekend == 'yes' ? true : false} onClick={(e) => extraPaying2('are_you_paying_extra_in_weekend', 'yes')} type="radio" id="accept1" name="are_you_paying_extra_in_weekend" />
                                                    <label htmlFor="accept1">Yes</label>
                                                </div>
                                                <div className="switchfieldinner">
                                                    <input checked={stepData.are_you_paying_extra_in_weekend == 'no' ? true : false} onClick={(e) => extraPaying2('are_you_paying_extra_in_weekend', 'no')} type="radio" id="completed1" name="are_you_paying_extra_in_weekend" />
                                                    <label htmlFor="completed1">No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-2 align-items-center justify-content-betweenm">
                                            <div>Shift loading</div>
                                            <div className="switch-field mr-0">
                                                <div className="switchfieldinner">
                                                    <input checked={stepData.are_you_paying_extra_in_overtime == 'yes' ? true : false} onClick={(e) => extraPaying2('are_you_paying_extra_in_overtime', 'yes')} type="radio" id="accept2" name="are_you_paying_extra_in_overtime" />
                                                    <label htmlFor="accept2">Yes</label>
                                                </div>
                                                <div className="switchfieldinner">
                                                    <input checked={stepData.are_you_paying_extra_in_overtime == 'no' ? true : false} onClick={(e) => extraPaying2('are_you_paying_extra_in_overtime', 'no')} type="radio" id="completed2" name="are_you_paying_extra_in_overtime" />
                                                    <label htmlFor="completed2">No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="d-flex mb-2 align-items-center justify-content-betweenm">
                                            <div>Overtime</div>
                                            <div className="switch-field mr-0">
                                                <div className="switchfieldinner">
                                                    <input checked={stepData.extrapay_overtime == 'yes' ? true : false} onClick={(e) => extraPaying2('extrapay_overtime', 'yes')} type="radio" id="accept4" name="extrapay_overtime" />
                                                    <label htmlFor="accept4">Yes</label>
                                                </div>
                                                <div className="switchfieldinner">
                                                    <input checked={stepData.extrapay_overtime == 'no' ? true : false} onClick={(e) => extraPaying2('extrapay_overtime', 'no')} type="radio" id="completed4" name="extrapay_overtime" />
                                                    <label htmlFor="completed4">No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-2 align-items-center justify-content-betweenm">
                                            <div>Weekend</div>
                                            <div className="switch-field mr-0">
                                                <div className="switchfieldinner">
                                                    <input checked={stepData.extrapay_allowances == 'yes' ? true : false} onClick={(e) => extraPaying2('extrapay_allowances', 'yes')} type="radio" id="accept5" name="extrapay_allowances" />
                                                    <label htmlFor="accept5">Yes</label>
                                                </div>
                                                <div className="switchfieldinner">
                                                    <input checked={stepData.extrapay_allowances == 'no' ? true : false} onClick={(e) => extraPaying2('extrapay_allowances', 'no')} type="radio" id="completed5" name="extrapay_allowances" />
                                                    <label htmlFor="completed5">No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-2 align-items-center justify-content-betweenm">
                                            <div>Bonuses</div>
                                            <div className="switch-field mr-0">
                                                <div className="switchfieldinner">
                                                    <input checked={stepData.extrapay_bonuses == 'yes' ? true : false} onClick={(e) => extraPaying2('extrapay_bonuses', 'yes')} type="radio" id="accept6" name="extrapay_bonuses" />
                                                    <label htmlFor="accept6">Yes</label>
                                                </div>
                                                <div className="switchfieldinner">
                                                    <input checked={stepData.extrapay_bonuses == 'no' ? true : false} onClick={(e) => extraPaying2('extrapay_bonuses', 'no')} type="radio" id="completed6" name="extrapay_bonuses" />
                                                    <label htmlFor="completed6">No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div className="col-md-12">
                                <button type="submit" className="batn batn-orange w-100" >Next</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }

    const Findstaff_form_4 = () => {
        const [errorMessage, setErrorMessage] = useState('');
        const [stepData, setStepData] = useState(manuallyFormData2);
        const today = new Date().toISOString().split('T')[0];
        const returnTaxTypeFun = (value) => { setStepFormData('required_tax_type', value); }

        const [selectedOptionsLanguage, setSelectedOptionsLanguage] = React.useState(stepData.language ? stepData.language : [{ value: '1', label: 'English' }]);
        const handleSelectChangeLanguage = (selected) => { setSelectedOptionsLanguage(selected); };

        const [selectedOptionsEductions, setSelectedOptionsEductions] = React.useState(stepData.required_education ? stepData.required_education : []);
        const handleSelectChangeEduction = (selected) => { setSelectedOptionsEductions(selected); };

        const [selectedOptionsQualification, setSelectedOptionsQualification] = React.useState(stepData.extra_qualification ? stepData.extra_qualification : []);
        const handleSelectChangeQualification = (selected) => { setSelectedOptionsQualification(selected); };

        const setStepFormData = (name, value) => { setStepData((prevFormData) => ({ ...prevFormData, [name]: value })); }

        const stepChangeFormFun = (event) => {
            const { name, value } = event.target;
            if (name == 'job_descriptions') {
                let { value } = event.target;
                const restrictedWords = ['male', 'female', 'man', 'men', 'woman', 'women', 'ladies', 'lady'];
                const removedWords = [];
                // Remove restricted words
                restrictedWords.forEach(word => {
                    const regex = new RegExp(`\\b${word}\\b`, 'gi');
                    if (regex.test(value)) { value = value.replace(regex, ''); removedWords.push(word); }
                });
                // Check word count
                const wordCount = value.trim().split(/\s+/).length;
                const exceedsWordLimit = wordCount > 1800;
                if (exceedsWordLimit) {
                    setErrorMessage('Text exceeds 1800 words limit.');
                } else if (removedWords.length > 0) {
                    setErrorMessage(`${removedWords.join(', ')} word is restricted and not allowed in description.`);
                } else { setErrorMessage(''); }
                setStepFormData(name, value);
            }
            else { setStepFormData(name, value); }
        }

        const [validated, setValidated] = useState(false);

        useEffect(() => {
            console.log(selectedOptionsLanguage);
            setStepFormData('language', selectedOptionsLanguage);
            setStepFormData('required_education', selectedOptionsEductions);
            setStepFormData('extra_qualification', selectedOptionsQualification);
        }, [selectedOptionsLanguage, selectedOptionsEductions, selectedOptionsQualification]);

        const onFinalSubmit = (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
            else {
                setStep(5);
                setManualShouldRunEffect(true);
                setManuallyFormData2(stepData);
            }
            setValidated(true);
        };



        return (
            <div className="staffpop">
                <div className="staffbanner text-center">

                    <h3><span className='text-white cursor_pointer' onClick={setStep3} ><i className="fa fa-chevron-left" aria-hidden="true"></i></span>Find a staff</h3>
                    <img src="/front/images/staffserach.png"></img>
                </div>
                <div className="stafformwrap commonform stepscount">

                    <Form noValidate validated={validated} onSubmit={onFinalSubmit} >
                        <div className="row">
                            <div className="col-md-12 mb-3"><div className="form-group"><h3>Step 3 of 3</h3></div></div>
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Required educational</label>
                                    <Select
                                        className={`react-select ${validated && !selectedOptionsEductions ? 'is-invalid' : ''}`}
                                        required={true}
                                        options={allEducationListOptions}
                                        isMulti
                                        name="education_qualification"
                                        value={selectedOptionsEductions}
                                        onChange={handleSelectChangeEduction}
                                        placeholder="Select options..."
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Required extra qualification</label>

                                    <Select
                                        className={`react_select}`}
                                        options={allQualificationListOptions}
                                        isMulti
                                        name="qualification"
                                        value={selectedOptionsQualification}
                                        onChange={handleSelectChangeQualification}
                                        placeholder="Select options..."
                                        styles={customStyles}
                                    />

                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Preferred language</label>
                                    <Select
                                        options={allLanguageListOption}
                                        isMulti
                                        value={selectedOptionsLanguage}
                                        onChange={handleSelectChangeLanguage}
                                        placeholder="Select options..."
                                        styles={customStyles}
                                        required={true}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Job end date<span className="starr">*</span></label>
                                    <Form.Control required={true} onChange={stepChangeFormFun} min={today} name="expire_date" type="datetime-local" value={stepData.expire_date} className="form-control" placeholder="Add distance from your location to hire" />
                                </div>
                            </div>

                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Job description<span className="starr">*</span></label>
                                    <textarea required={true} onChange={stepChangeFormFun} value={stepData.job_descriptions} name="job_descriptions" className="form-control" placeholder="Enter Job Description"></textarea>
                                    {errorMessage ? <div className='text-danger'>{errorMessage}</div> : <></>}
                                </div>
                            </div>


                            <div className="col-md-12 mb-4">
                                <div className="form-group">
                                    <label className="form-label w-100">Required Tax type</label>
                                    <div className="switch-field mr-0">
                                        <div className="switchfieldinner">
                                            <input checked={stepData.required_tax_type == 'abn' ? true : false} onClick={(e) => returnTaxTypeFun('abn')} type="radio" id="abn" name="required_tax_type" />
                                            <label htmlFor="abn">ABN</label>
                                        </div>
                                        <div className="switchfieldinner">
                                            <input checked={stepData.required_tax_type == 'tfn' ? true : false} onClick={(e) => returnTaxTypeFun('tfn')} type="radio" id="tfn" name="required_tax_type" />
                                            <label htmlFor="tfn">TFN</label>
                                        </div>
                                        <div className="switchfieldinner">
                                            <input checked={stepData.required_tax_type == 'both' ? true : false} onClick={(e) => returnTaxTypeFun('both')} type="radio" id="bothtype" name="tax_type" value="both" />
                                            <label htmlFor="bothtype">TFN/ABN</label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="batn batn-orange w-100" >Next</button>
                            </div>
                        </div>
                    </Form>


                </div>
            </div>
        )
    }

    const Findstaff_form_5 = () => {

        // console.log(manuallyFormData2);
        //console.log(manuallyFormData2.availability);

        const weekNameArray = (manuallyFormData2.availability ? manuallyFormData2.availability : WEEKARRAY_CONST);
        // console.log(weekNameArray);
        //const [weekNameArrayManual, setWeekNameArrayManual] = useState(stepData.availibility ? stepData.availibility : WEEKARRAY_CONST);
        /*Language*/
        const selectedOptionsLanguage = (manuallyFormData2.language);
        const selectedOptionsEductions = (manuallyFormData2.required_education ? manuallyFormData2.required_education : []);
        const selectedOptionsQualification = (manuallyFormData2.extra_qualification ? manuallyFormData2.extra_qualification : []);

        console.log("-----------------------");
        console.log(manuallyFormData2);
        console.log(selectedOptionsEductions);
        console.log(selectedOptionsQualification);

        const GetJobTitle = (attr) => {
            const item = jobTitleList.find((item) => item.value === attr.id);
            return item ? item.label : '.....';
        }

        const GetJobType = (attr) => {
            const item = jobTypeList.find((item) => item.id === attr.id);
            return item ? item.jobtype : '.....';
        }


        const GetEducationTitle = (attr) => {
            const item = allQualificationList.find((item) => item.id === attr.id);
            return item ? item.qualification_name : '.....';
        }

        const GetLablesValue = (arr) => {
            const labels = arr.attr.map(item => item.label);
            const result = labels.join(', ');
            return result;
        }
        const description = manuallyFormData2.job_descriptions;
        const [showFullDescription, setShowFullDescription] = useState(false);
        // Split the description into words and select the first 20 words
        const limitedDescription = (description) ? description.split(' ').slice(0, 20).join(' ') : '';

        //const limitedDescription = description;

        const handleReadMoreClick = () => { setShowFullDescription(!showFullDescription); };

        return (
            <div className="staffpop">
                <div className="staffbanner text-center">
                    <h3 >
                        {jobidarray_switch.actiontype != 'preview' && <span className='text-white cursor_pointer' onClick={setStep4} ><i className="fa fa-chevron-left" aria-hidden="true"></i></span>}
                        Find a staff
                    </h3>
                    <img src="/front/images/staffserach.png"></img>
                </div>
                <div className="stafformwrap commonform stepscount">
                    <div className="row">
                        {/* step1 */}
                        <>
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <h3 className="d-flex justify-content-between" >Preview
                                        {
                                            screenTypeCall == 'preview' ?
                                                <>
                                                    {
                                                        screenType == 'completed_job' ?
                                                            <><div className="editbtn" onClick={(e) => setStep1()} ><img src="/front/images/edit.png" /></div></> :
                                                            <></>
                                                    }
                                                </>
                                                : <><div className="editbtn" onClick={(e) => setStep2()} ><img src="/front/images/edit.png" /></div></>
                                        }

                                    </h3>
                                </div>
                            </div>
                            <div className="col-md-12 mb-2">
                                <div className="editblock">
                                    <label>Job Title</label>
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4><GetJobTitle id={manuallyFormData2.job_title} /></h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-2">
                                <div className="editblock">
                                    <label>Job type</label>
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4><GetJobType id={manuallyFormData2.job_type} /></h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-2">
                                <div className="editblock joblocationblock">
                                    <label>Work location</label>
                                    <div className="editblockinner d-flex align-items-center">
                                        <div className="d-flex flex-wrap align-items-center">
                                            {manuallyFormData2.job_location}
                                            {
                                                // (typeof (manuallyFormData2.job_location) != 'undefined') && manuallyFormData2.job_location.map((tag, index) => (
                                                //     <div className="singletag d-flex" key={index}>
                                                //         <span>{tag}</span>
                                                //     </div>
                                                // ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-2">
                                <div className="editblock">
                                    <label>Range from location </label>
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4>{manuallyFormData2.range_from_location} km</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-2">
                                <div className="editblock">
                                    <label>How many staff looking for</label>
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4>
                                            {manuallyFormData2.staff_number}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </>

                        {/* step2 */}
                        <>
                            <div className="col-md-12 mb-2">
                                <div className="editblock">
                                    <label>Total experience needed </label>
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4>{manuallyFormData2.total_experience_year}{manuallyFormData2.total_experience_month ? `.${manuallyFormData2.total_experience_month}` : ''} {manuallyFormData2.total_experience_year ? 'Years' : ''} {manuallyFormData2.i_am_fresher ? '(Freshers can also apply)' : ''} </h4>
                                    </div>
                                </div>
                            </div>


                            {manuallyFormData2.fixed_salary ? <div className="col-md-12 mb-2">
                                <div className="editblock">
                                    <label>Fixed salary you are offering ?</label>
                                    {
                                        <div className="editblockinner d-flex align-items-start">
                                            {/* <h4>Minimum ${manuallyFormData2.salary_minimum}</h4> */}
                                            <h4><FormatCurrency price={manuallyFormData2.fixed_salary} /></h4>
                                        </div>
                                    }
                                </div>
                            </div> :
                                <div className="col-md-12 mb-2">
                                    <div className="editblock">
                                        <label>Salary you are offering </label>
                                        {
                                            <div className="editblockinner d-flex align-items-start">
                                                {/* <h4>Minimum ${manuallyFormData2.salary_minimum}, Maximum ${manuallyFormData2.salary_maximum}</h4> */}
                                                <h4>
                                                    Minimum  <FormatCurrency price={manuallyFormData2.salary_minimum} /> ,
                                                    Maximum <FormatCurrency price={manuallyFormData2.salary_maximum} />
                                                </h4>
                                            </div>
                                        }

                                    </div>
                                </div>
                            }
                            <div className="col-md-12 mb-2">
                                <div className="editblock saleryoffering">
                                    <label>Salary you are offering </label>
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4>
                                            <div><span>Public holidays:</span><span className='text-capitalize'>{manuallyFormData2.are_you_paying_extra_in_holiday}</span></div>
                                            <div><span>Weekend:</span><span className='text-capitalize'>{manuallyFormData2.are_you_paying_extra_in_weekend}</span></div>
                                            <div><span>Overtime:</span><span className='text-capitalize'>{manuallyFormData2.are_you_paying_extra_in_overtime}</span></div>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-2">
                                <div className="editblock staffavailability">
                                    <label>Availability to work</label>
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4>
                                            {
                                                weekNameArray.map((item, index) => (
                                                    <div>
                                                        <span>{item.name}:</span>
                                                        <span className="text-underline1"> {item.from_value ? item.from_value + ' am' : 'Nill'} </span>
                                                        <span>To</span>
                                                        <span className="text-underline1"> {item.to_value ? item.to_value + ' pm' : 'Nill'} </span>
                                                    </div>
                                                ))
                                            }
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </>

                        {/* step3 */}
                        <>

                            <div className="col-md-12 mb-2">
                                <div className="editblock saleryoffering">
                                    <label>Required education </label>
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4><GetLablesValue attr={selectedOptionsEductions} /></h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-2">
                                <div className="editblock saleryoffering">
                                    <label>Required qualification </label>
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4><GetLablesValue attr={selectedOptionsQualification} /></h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-2">
                                <div className="editblock joblocationblock">
                                    <label>Preferred Language</label>
                                    <div className="editblockinner d-flex align-items-center">
                                        <div className="tagswrap d-flex flex-wrap align-items-center">
                                            {
                                                (typeof (selectedOptionsLanguage) != 'undefined' && selectedOptionsLanguage.length) ?
                                                    selectedOptionsLanguage.map((item, index) =>
                                                        (<div className="singletag d-flex mr-0"><span>{item.label}</span></div>)
                                                    )
                                                    : <>Not found</>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mb-2">
                                <div className="editblock saleryoffering">
                                    <label>Job end date </label>
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4>
                                            {manuallyFormData2.expire_date_text}
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mb-3">
                                <div className="editblock joblocationblock">
                                    <label className="form-label">Description<span className="starr">*</span></label>
                                    <div className="editblockinner d-flex align-items-start">
                                        {/* {manuallyFormData2.job_description} */}
                                        {
                                            description.length < 125
                                                ?
                                                description
                                                :
                                                showFullDescription ? (
                                                    <p>
                                                        {description}
                                                        <br></br>
                                                        <a href="#" className='text_safron' onClick={handleReadMoreClick}> hide</a>
                                                    </p>
                                                ) : (
                                                    <p>
                                                        {limitedDescription}
                                                        <br></br>
                                                        <a href="#" className='text_safron' onClick={handleReadMoreClick}> read more...</a>
                                                    </p>
                                                )

                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mb-4">
                                <div className="editblock saleryoffering">
                                    <label>Required Tax type <span className='text-uppercase'>{manuallyFormData2.required_tax_type}</span> </label>
                                    <div className="editblockinner d-flex align-items-start">
                                        <h4 className='text-uppercase'>{manuallyFormData2.required_tax_type}</h4>
                                    </div>
                                </div>
                            </div>
                        </>

                        {/* step4 */}


                        {
                            (jobidarray_switch.id != 'new' && jobidarray_switch.actiontype == 'preview' && fsmodelaction_switch.model_action == 'close') ?
                                <></>
                                :
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <button onClick={() => { setManualShouldRunEffect(true); manuallyFormDataSubmitFun(1) }} className="batn batn-purple w-100" >
                                                Search Now
                                            </button>
                                            <span className="inforpop">
                                                <span className="infoicon"><img src="/front/images/infoicoin.png" /></span>
                                                <span className="infodtl">
                                                    <h4>Mannual Search</h4>
                                                    <p>Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Cras dapibus. Nam commodo suscipit quam.</p>
                                                </span>
                                            </span>
                                        </div>

                                    </div>
                                </div>

                        }


                    </div>
                </div>
            </div>
        )
    }

    /*----------------------------------"Quick search"-----------------------------------------------*/



    //-33.8688197, lng: 151.2092955
    const [quickFormData2, setquickFormData2] = useState({
        'job_title': '',
        'industry': '',
        'latitude': -33.8688197,
        'longitude': 151.2092955,
        'total_experience_year': '',
        'total_experience_month': '',
        'staff_number': '',
        'salary_type': 'hourly',
        'salary_minimum': '',
        'salary_maximum': '',
        'work_location': '',
        'range_from_location': '',
        'expire_date': '',
        'start_date': '',
        'expire_date_text': '',
        'start_date_text': '',
        'salary_you_are_offering': '',
        'job_descriptions': '',
        'requirement': '',
        'i_am_fresher': '',
        'required_tax_type': 'abn',
        'address': [],
        'availability': WEEKARRAY_CONST,
    });

    const [weekNameArray, setWeekNameArray] = useState(quickFormData2.availibility ? quickFormData2.availibility : WEEKARRAY_CONST);
    const getJobTitleValue = (value) => {
        const item = jobTitleList.find(item => item.value === value);
        return item ? item.label : null;
    }

    const getIndustryValue = (value) => {
        const item = get_all_industry.find(item => item.value === value);
        return item ? item.label : null;
    }



    const [quickFormEditId, setQuickFormEditId] = useState(0);
    const [shouldRunEffect, setShouldRunEffect] = useState(false);

    function quickFormDataSubmitFun2(att) {
        console.log(att);
        quickFormDataSubmitFun(1);
    }

    const quickFormDataSubmitFun = (finalStepSubmit = 0) => {

        console.log('calling...');
        console.log(finalStepSubmit);

        if (quickFormData2.job_title) {
            console.log('calling...=' + finalStepSubmit);

            const formData = new FormData();
            formData.append('user_unique_id', useriddata.unique_id);
            formData.append('edit_id', quickFormEditId);
            formData.append('action_type', jobidarray_switch.actiontype);
            formData.append('screen_type', jobidarray_switch.screentype);
            formData.append('status', ((finalStepSubmit == 1) ? 'active' : 'draft'));
            formData.append('formdata', JSON.stringify(quickFormData2));
            try {
                axios.post('https://squadgoo.com.au/api.squadgoo.com.au/action_quicksearch_action', (formData))
                    .then((response) => {
                        if (response.status == 200 && response.data.status == 1) {
                            setQuickFormEditId(response.data.data.edit_id);
                            setShouldRunEffect(false);
                            if (finalStepSubmit == 1) {
                                setTimeout(window.location.replace("/quick-search?formid=" + response.data.data.edit_id), 1000);
                            }
                        }
                    }).catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }

        }
    }
    useEffect(() => { quickFormDataSubmitFun(); }, [quickFormData2]);


    const Findstaff_form_6 = () => {

        const [stepOneData, setstepOneData] = useState(quickFormData2);

        const [getNewTitle, setgetNewTitle] = useState('');
        const [isAddNewJobtitleError, setIsAddNewJobtitleError] = useState(false);

        const [isAddNewJobtitleErrorMessage, setIsAddNewJobtitleErrorMessage] = useState('');
        const [isAddNewJobModalShow, setIsAddNewJobModalShow] = useState(false);
        const [lastInsertJobId, setLastInsertJobId] = useState(stepOneData.job_title);

        const [isAddNewIndustryErrorMessage, setIsAddNewIndustryErrorMessage] = useState('');
        const [isAddNewIndustryModalShow, setIsAddNewIndustryModalShow] = useState(false);
        const [lastInsertIndustryId, setLastInsertIndustryId] = useState(stepOneData.industry);

        const stepOneFormFun = (event) => {
            const { name, value } = event.target;
            setstepOneData((prevFormData) => ({ ...prevFormData, [name]: value }));
        }

        const joBTitleSelected = (select) => {
            setIsAddNewJobtitleErrorMessage('');
            setIsAddNewJobModalShow(false);
            if (select.value === 'other') { setIsAddNewJobModalShow(true) }
            else {
                setstepOneData((prevFormData) => ({ ...prevFormData, ['job_title']: select.value }));
                setLastInsertJobId(select.value);
            }
        }

        const industryTitleSelected = (select) => {
            setIsAddNewIndustryErrorMessage('');
            setIsAddNewIndustryModalShow(false);
            if (select.value === 'other') { setIsAddNewIndustryModalShow(true) }
            else {
                setstepOneData((prevFormData) => ({ ...prevFormData, ['industry']: select.value }));
                setLastInsertIndustryId(select.value);
            }
        }

        const addJobTitleInListfun = (event) => {
            event.preventDefault();
            let value = getNewTitle;
            if (value && value.length > 3) {
                setIsAddNewJobtitleError(false);
                (async () => {
                    try {
                        const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/set_new_jobtitle/${useriddata.unique_id}/${value}`);
                        if (resData.data.status == 1) {
                            setJobtitleList(resData.data.data.jobtitle_option);
                            setLastInsertJobId(resData.data.data.last_id);
                            setIsAddNewJobtitleErrorMessage(resData.data.message);
                        } else { setIsAddNewJobtitleErrorMessage(resData.data.message) }
                    } catch (error) { console.log(error); }
                })();
            } else { setIsAddNewJobtitleError(true); setIsAddNewJobtitleErrorMessage('Please enter title'); }
        }

        const [validated, setValidated] = useState(false);
        const handleStepOneSubmit = (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
            else {
                setquickFormData2(stepOneData);
                setShouldRunEffect(true);
                setStep(7)
            }
            setValidated(true);
        };

        const [classForTitleError, setClassForTitleError] = useState('');
        const [classForCategoryError, setClassForCategoryError] = useState('');
        const handleJobTitleChange = (selectedJobTitle) => {
            setIsAddNewJobtitleErrorMessage('');
            setIsAddNewJobModalShow(false);
            if (selectedJobTitle.value === 'other') { setIsAddNewJobModalShow(true) }
            else {
                setstepOneData((prevFormData) => ({ ...prevFormData, ['job_title']: selectedJobTitle.value }));
                setLastInsertJobId(selectedJobTitle.value);
            }
         };


        return (
            <div className="staffpop">
                <div className="staffbanner staffbannernew text-center">
                    <h3  ><span className='text-white cursor_pointer' onClick={setStep1} ><i className="fa fa-chevron-left" aria-hidden="true"></i></span> Quick Search</h3>
                    <img src="/front/images/staffserach.png"></img>
                </div>
                <div className="stafformwrap commonform stepscount">
                    <Form noValidate validated={validated} onSubmit={handleStepOneSubmit} className="">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <h3>Step 1 of 6</h3>
                                </div>
                            </div>

                            {/* <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Job title<span className="starr">*</span></label>
                                    <Select
                                        className='react_select'
                                        options={jobTitleList}
                                        name="job_title"
                                        isSearchable
                                        value={jobTitleList.find(item => item.value === lastInsertJobId)}
                                        onChange={joBTitleSelected}
                                        placeholder="Search..."
                                    />
                                </div>
                            </div> */}
                            <JobTitleCAtegoryComp
                                defaultJobID={lastInsertJobId}
                                onJobTitleChange={handleJobTitleChange}
                                classForCategory={classForCategoryError}
                                classForTitle={classForTitleError}
                            />
                            {isAddNewJobModalShow && <div className="col-md-12 mb-3">
                                <div className="input-group mb-2">
                                    <input type="text" required={true} onChange={(e) => setgetNewTitle(e.target.value)} value={getNewTitle} className="form-control" name="add_job_title" placeholder="Enter job title" />
                                    <div className="input-group-append"><button className="batn batn-orange  add_newjobtitle" onClick={addJobTitleInListfun}>Add Title</button></div>
                                    {
                                        isAddNewJobtitleErrorMessage && <><div className='error_message'>{isAddNewJobtitleErrorMessage}</div></>
                                    }
                                </div>
                            </div>}
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Industry<span className="starr">*</span></label>

                                    <Select
                                        className='react_select'
                                        options={get_all_industry}
                                        name="industry"
                                        isSearchable
                                        value={get_all_industry.find(item => item.value === lastInsertIndustryId)}
                                        onChange={industryTitleSelected}
                                        placeholder="Search..."
                                    />

                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Total experience needed<span className="starr">*</span></label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <select required={true} onChange={stepOneFormFun} name="total_experience_year" className="form-control">
                                                <YearExpNumberLoop select_attr={stepOneData.total_experience_year} />
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <select required={true} onChange={stepOneFormFun} name="total_experience_month" className="form-control">
                                                <MonthExpNumberLoop select_attr={stepOneData.total_experience_month} />
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12"><button type="submit" className="batn batn-purple w-100" >Next</button></div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
    const Findstaff_form_7 = () => {
        const [stepTwoData, setstepTwoData] = useState(quickFormData2);
        console.log(stepTwoData);
        const [radius, setRadius] = useState(5000); // Default radius in meters
        const [rangeInput, setRangeInput] = useState(5); // Default range in kilometers
        const [center, setMapCenter] = useState({ lat: stepTwoData.latitude, lng: stepTwoData.longitude });
        //const [radius, setRadius] = useState(10000);
        const [zoomLevel, setZoomLevel] = useState(10);

        const handleRangeChange = (event) => {
            const newRange = parseFloat(event.target.value);
            setRadius(newRange * 1000); // Convert kilometers to meters
            setRangeInput(newRange);
            //range_from_location
            setstepTwoData((prevFormData) => ({ ...prevFormData, ['range_from_location']: newRange }));
            setZoomLevel(calculateZoomLevel(newRange * 1000));
        };

        // const stepTwoFormFun = (event) => {
        //     const { name, value } = event.target;
        //     setstepTwoData((prevFormData) => ({ ...prevFormData, [name]: value }));
        // }

        const stepTwoFormFun = (event) => {
            const { name, value } = event.target;
          
            setstepTwoData((prevFormData) => {
              let updatedData = { ...prevFormData, [name]: value };
          
              if (name === "start_date") {
                // Reset end date if it's before the new start date
                if (prevFormData.expire_date && value >= prevFormData.expire_date) {
                  updatedData.expire_date = "";
                }
              }
          
              return updatedData;
            });
          };
          

        const [validated, setValidated] = useState(false);
        const handleStepTwoSubmit = (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
            else {
                setquickFormData2(stepTwoData);
                setStep(8);
                setShouldRunEffect(true);
            }
            setValidated(true);
        };

        const handlePlaceSelect = async () => {
            const place = autocompleteRef.current.getPlace();
            console.log('Place:', place);

            if (!place.geometry) {
                console.error("No details available for input: ", place);
                return;
            }

            try {
                const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${place.place_id}&key=${GAPI}&libraries=places&v=weekly&callback=initMap&loading=async`);

                console.log(response)
                if (response.data.results.length > 0) {
                    const { lat, lng } = response.data.results[0].geometry.location;
                    const formattedAddress = response.data.results[0].formatted_address;

                    setstepTwoData((prevFormData) => ({
                        ...prevFormData,
                        ['address']: { lat, lng, formattedAddress }
                    }));

                    setstepTwoData((prevFormData) => ({
                        ...prevFormData,
                        ['work_location']: formattedAddress
                    }));

                    setMapCenter({ lat: lat, lng: lng })
                } else {
                    console.error("No results found for the provided address.");
                }
            } catch (error) {
                console.error("Error fetching geocode data:", error);
            }
        };


        const calculateZoomLevel = (radius) => {
            if (radius > 1500 * 1000) { return 3; }
            else if (radius > 1000 * 1000) { return 4; }
            else if (radius > 750 * 1000) { return 5; }
            else if (radius > 300 * 1000) { return 6; }
            else if (radius > 100 * 1000) { return 7; }
            else if (radius > 70 * 1000) { return 8; }
            else if (radius > 30 * 1000) { return 9; }
            else { return 10; }
        };

        // Update zoom level when radius changes

        const todayDate = new Date().toISOString().slice(0, 16);

        return (
            <div className="staffpop">
                <div className="staffbanner staffbannernew text-center">
                    <h3  ><span className='text-white cursor_pointer' onClick={setStep6} ><i className="fa fa-chevron-left" aria-hidden="true"></i></span> Quick Search</h3>
                    <img src="/front/images/staffserach.png"></img>
                </div>
                <div className="stafformwrap commonform stepscount">
                    <Form noValidate validated={validated} onSubmit={handleStepTwoSubmit} className="">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <h3>Step 2 of 6</h3>
                                </div>
                            </div>
                            <div className="col-md-12 mb-4">
                                <div className="form-group"><label className="form-label">How many staff looking for<span className="starr">*</span> </label>
                                    <input value={stepTwoData.staff_number} onChange={stepTwoFormFun} required={true} name="staff_number" placeholder="Total staff numbers" type="number" className="form-control form-control" /></div>
                            </div>


                            <div className="col-md-12 mb-3">
                                <div className="form-group"><label className="form-label">Work location<span className="starr">*</span></label>
                                    <Autocomplete
                                        onLoad={(autocomplete) => {
                                            autocompleteRef.current = autocomplete;
                                        }}
                                        onPlaceChanged={handlePlaceSelect}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Enter a location"
                                            className='form-control'
                                            required={true} name="work_location" onChange={stepTwoFormFun} value={stepTwoData.work_location}
                                        />
                                    </Autocomplete>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Range from location<span className="starr">*</span></label>
                                    <input value={rangeInput}
                                        onChange={handleRangeChange} required={true} name="range_from_location" min="1" max="2500" placeholder="Add distance from your location to hire" type="range" className="width-100percent" />
                                    {stepTwoData.range_from_location && <span className="fieldsub">{stepTwoData.range_from_location} km</span>}
                                    {/* {radius && <span className="fieldsub">{stepTwoData.range_from_location} km</span>} */}
                                </div>
                            </div>

                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <GoogleMap
                                        mapContainerStyle={{ height: "300px", width: "100%" }}
                                        zoom={zoomLevel}
                                        center={center}
                                    >
                                        {radius && (
                                            <Circle
                                                center={center}
                                                radius={parseFloat(radius)}
                                                options={{
                                                    strokeColor: "#000000",
                                                    strokeOpacity: 0.8,
                                                    strokeWeight: 2,
                                                    fillColor: "#000000",
                                                    fillOpacity: 0.35,
                                                    clickable: false,
                                                    draggable: false,
                                                    editable: false,
                                                    visible: true,
                                                }}
                                            />
                                        )}
                                        <Marker position={center} />
                                    </GoogleMap>
                                </div>
                            </div>

                           
                           
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="form-label">Start date<span className="starr">*</span></label>
                                            <input value={stepTwoData.start_date} onChange={stepTwoFormFun} required={true}  min={todayDate} name='start_date' type="datetime-local" className="form-control"></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="form-label">Job end date<span className="starr">*</span></label>
                                            <input value={stepTwoData.expire_date} onChange={stepTwoFormFun} required={true}   min={stepTwoData.start_date || todayDate} name='expire_date' type="datetime-local" className="form-control"></input>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-12">
                                <button type="submit" className="batn batn-purple w-100"  >Next</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }

    const Findstaff_form_8 = () => {
        console.log("8");
        const [stepThreeData, setstepThreeData] = useState(quickFormData2);
        console.log(stepThreeData);

        const [errorMessage, setErrorMessage] = useState('');
        const [errorMessage2, setErrorMessage2] = useState('');

        const [shrtDesWordCount, setShrtDesWordCount] = useState(0);
        const [requirementCount, setRequirementCount] = useState(0);
        const maxWords = 1000;

        const stepThreeFormFun = (event) => {
            const { name, value } = event.target;
            if (name == 'job_descriptions') {
                let { value } = event.target;
                const restrictedWords = ['male', 'female', 'man', 'men', 'woman', 'women', 'ladies', 'lady'];
                const removedWords = [];
                restrictedWords.forEach(word => {
                    const regex = new RegExp(`\\b${word}\\b`, 'gi');
                    if (regex.test(value)) {
                        value = value.replace(regex, '');
                        removedWords.push(word);
                    }
                });
                const wordCount = value.trim().split(/\s+/).length;
                setShrtDesWordCount(wordCount);
                const exceedsWordLimit = wordCount > maxWords;
                if (exceedsWordLimit) { setErrorMessage(`Text exceeds ${maxWords} words limit.`); }
                else if (removedWords.length > 0) { setErrorMessage(`${removedWords.join(', ')} word is restricted and not allowed in description.`); }
                else { setErrorMessage(''); }
                console.log(value);
                setstepThreeData((prevFormData) => ({ ...prevFormData, [name]: value }));
                //setquickFormData2((prevFormData) => ({ ...prevFormData, [name]: value }));
            }
            else if (name == 'requirement') {
                let { value } = event.target;
                const restrictedWords = ['male', 'female', 'man', 'men', 'woman', 'women', 'ladies', 'lady'];
                const removedWords = [];
                restrictedWords.forEach(word => {
                    const regex = new RegExp(`\\b${word}\\b`, 'gi');
                    if (regex.test(value)) {
                        value = value.replace(regex, '');
                        removedWords.push(word);
                    }
                });
                const wordCount = value.trim().split(/\s+/).length;
                setRequirementCount(wordCount);
                const exceedsWordLimit = wordCount > maxWords;
                if (exceedsWordLimit) { setErrorMessage2(`Text exceeds ${maxWords} words limit.`); }
                else if (removedWords.length > 0) { setErrorMessage2(`${removedWords.join(', ')} word is restricted and not allowed in description.`); }
                else { setErrorMessage2(''); }
                setstepThreeData((prevFormData) => ({ ...prevFormData, [name]: value }));
            } else {
                setstepThreeData((prevFormData) => ({ ...prevFormData, [name]: value }));
            }
        }

        const [validated, setValidated] = useState(false);
        const handleStepThreeSubmit = (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
            else {
                setquickFormData2(stepThreeData);
                setStep(9);
                setShouldRunEffect(true);
            }
            setValidated(true);
        };


        /*---------------------------*/
        const handleBlur = () => {
            const minSalary = stepThreeData.salary_minimum;
            const maxSalary = stepThreeData.salary_maximum;
            if (minSalary !== '' && maxSalary !== '' && parseInt(minSalary, 10) > parseInt(maxSalary, 10)) {
                // Swap values if minimum is greater than maximum
                const temp = minSalary;
                setstepThreeData((prevFormData) => ({ ...prevFormData, ['salary_maximum']: temp }));
                setstepThreeData((prevFormData) => ({ ...prevFormData, ['salary_minimum']: maxSalary }));
            }
        };
        const stepChangeFormFun = (event) => {
            const { name, value, type, checked } = event.target;
            if (type === 'checkbox') {
                setstepThreeData((prevFormData) => ({ ...prevFormData, [name]: checked }));
            } else {
                if (event.target.name == 'salary_maximum' && event.target.value.length > 6) {
                    return false;
                }
                if (event.target.name == 'salary_minimum' && event.target.value.length > 6) {
                    return false;
                }
                setstepThreeData((prevFormData) => ({ ...prevFormData, [name]: value }));
            }

        }

        return (
            <div className="staffpop">
                <div className="staffbanner staffbannernew text-center">
                    <h3  ><span className='text-white cursor_pointer' onClick={setStep7} ><i className="fa fa-chevron-left" aria-hidden="true"></i></span> Quick Search</h3>
                    <img src="/front/images/staffserach.png"></img>
                </div>
                <div className="stafformwrap commonform stepscount">
                    <Form noValidate validated={validated} onSubmit={handleStepThreeSubmit} className="">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <h3>Step 3 of 6</h3>
                                </div>
                            </div>
                            {/* {quickFormData2.salary_type} */}
                            <div className="col-md-12">
                                <div className="form-group mb-3">
                                    <label className="form-label">Salary type</label>
                                    <select required={true} onChange={stepThreeFormFun} name="salary_type" className="form-control">
                                        {
                                            SALARY_TYPE_JSON.map((item, key) => (
                                                <><option selected={stepThreeData.salary_type == item.key} key={item.key} value={item.key}>{item.value}</option></>
                                            ))
                                        }
                                        {/* <option key={'hourly'} value={'hourly'}>Hourly</option>
                                        <option key={'fixed'} selected={stepThreeData.salary_type == 'fixed'} value={'fixed'}>Fixed</option> */}
                                    </select>
                                </div>
                            </div>
                            {/* {stepThreeData.salary_type} */}
                            {stepThreeData.salary_type == 'hourly' ? <div className="col-md-12 mb-0">
                                <div className="form-group">
                                    <label className="form-label">Salary you are offering<span className="starr">*</span></label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="input-group mb-2 salaryfield2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input required={true} type="number" name="salary_minimum" onChange={stepChangeFormFun} onBlur={handleBlur} value={stepThreeData.salary_minimum} maxlength="5" className="form-control" placeholder="Minimum" />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mt-2 text-center">To</div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="input-group mb-2 salaryfield2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input required={true} type="number" name="salary_maximum" onChange={stepChangeFormFun} onBlur={handleBlur} value={stepThreeData.salary_maximum} maxlength="5" className="form-control" placeholder="Maximum" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                :
                                <div className="col-md-12 mb-3">
                                    <div className="form-group">
                                        <label className="form-label">Fixed salary you are offering ?</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="input-group mb-2 salaryfield2">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">$</div>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        onChange={stepThreeFormFun}
                                                        name="fixed_salary"
                                                        value={stepThreeData.fixed_salary}
                                                        maxlength="3"
                                                        className="form-control"
                                                        placeholder="Fixed salary you are offering"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            }

                            {/* <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Salary you are offering<span className="starr">*</span></label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="input-group mb-2 salaryfield">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input onChange={stepThreeFormFun} value={stepThreeData.salary_you_are_offering} required={true} type="number" name="salary_you_are_offering" maxlength="3" className="form-control" placeholder="Minimum" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Short job description<span className="starr">*</span></label>
                                    <textarea onChange={stepThreeFormFun} name='job_descriptions' className="form-control quictextarea" required={true} placeholder='Enter small job description'>{stepThreeData.job_descriptions}</textarea>
                                    {
                                        errorMessage ?
                                            <><div className='text-danger'>{errorMessage}</div></>
                                            :
                                            <></>
                                    }
                                    <div>
                                        Word limit: {shrtDesWordCount}/{maxWords}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <label className="form-label">Requirement<span className="starr">*</span></label>
                                    <textarea onChange={stepThreeFormFun} name='requirement' className="form-control quictextarea" required={true} placeholder='Enter requirement like Uniform, Safty boots, Licence etc'>{stepThreeData.requirement}</textarea>
                                    {
                                        errorMessage2 ?
                                            <><div className='text-danger'>{errorMessage2}</div></>
                                            :
                                            <></>
                                    }
                                    <div>
                                        Word limit: {requirementCount}/{maxWords}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button disabled={(errorMessage || errorMessage2) ? true : false} type="submit" className="batn batn-purple w-100" >Next</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
    const Findstaff_form_9 = () => {
        const [stepFourData, setstepFourData] = useState(quickFormData2);
        const stepFourFormFun = (taxvalue) => {
            setstepFourData((prevFormData) => ({ ...prevFormData, ['required_tax_type']: taxvalue }));
        }

        const [validated, setValidated] = useState(false);
        const handleStepFourSubmit = (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
            else {
                setquickFormData2(stepFourData);
                setStep(10);
                setShouldRunEffect(true);
            }
            setValidated(true);
        };


        return (
            <div className="staffpop">
                <div className="staffbanner staffbannernew text-center">
                    <h3  ><span className='text-white cursor_pointer' onClick={setStep8} ><i className="fa fa-chevron-left" aria-hidden="true"></i></span> Quick Search</h3>
                    <img src="/front/images/staffserach.png"></img>
                </div>
                <div className="stafformwrap commonform stepscount">
                    <Form noValidate validated={validated} onSubmit={handleStepFourSubmit} className="">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <h3>Step 4 of 6</h3>
                                </div>
                            </div>
                            <div className="col-md-12 mb-4">
                                <div className="form-group">
                                    <label className="form-label w-100">Required Tax type</label>
                                    <div className="switch-field mr-0">

                                        <div className="switchfieldinner">
                                            <input type="radio" id="abn" name="required_tax_type" onChange={() => stepFourFormFun('abn')} checked={stepFourData.required_tax_type == 'abn'} />
                                            <label for="abn">ABN</label>
                                        </div>
                                        <div className="switchfieldinner">
                                            <input type="radio" id="tfn" name="required_tax_type" onChange={() => stepFourFormFun('tfn')} checked={stepFourData.required_tax_type == 'tfn'} />
                                            <label for="tfn">TFN</label>
                                        </div>
                                        <div className="switchfieldinner">
                                            <input type="radio" id="both" name="required_tax_type" onChange={() => stepFourFormFun('both')} checked={stepFourData.required_tax_type == 'both'} />
                                            <label for="both">TFN/ABN</label>
                                        </div>
                                        {/* <div className="switchfieldinner"><input type="radio" id="bothtype" name="tax_type" value="both" /><label for="bothtype">BOTH</label></div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="batn batn-purple w-100" >Next</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }

    const Findstaff_form_10 = () => {
        const inputRefs = useRef([]);
        const inputRefs2 = useRef([]);
        const [activeIndex, setActiveIndex] = useState(null);
        const weekdata = weekNameArray?.weekdata ? weekNameArray.weekdata : weekNameArray
        const [weekNameArrayQuick, setWeekNameArrayQuick] = useState(weekdata ? weekdata : WEEKARRAY_CONST);
        const [iAmFresher, setIAmFresher] = useState(quickFormData2.i_am_fresher);

        const checkValueofDay = (key, type, value, index_key) => {
            const newArray = [...weekNameArray];
            const index = newArray.findIndex(item => item.key === key);
            if (index !== -1) {
                if (type === 'from') { newArray[index].from_value = value; inputRefs2.current[index].focus(); }
                else if (type === 'to') { newArray[index].to_value = value; }
                else {
                    newArray[index].checked = value;
                    newArray[index].from_value = "";
                    newArray[index].to_value = "";
                    if (value == true && !newArray[index].from_value && !newArray[index].to_value) {
                        newArray[index].from_value = "00:00";
                        newArray[index].to_value = "23:59";
                        inputRefs.current[index].focus();
                        setActiveIndex(index);
                    }
                    // newArray[index].checked = value;
                    // if (value == false) {
                    //     newArray[index].from_value = '';
                    //     newArray[index].to_value = '';
                    // }
                    // newArray[index].checked = false;
                    // if (newArray[index].from_value && newArray[index].to_value) {
                    //     newArray[index].checked = true;
                    // }
                }

                // if (newArray[index].from_value && newArray[index].to_value) {
                //     newArray[index].checked = true;
                // }
                //setWeekNameArray(newArray);
                setWeekNameArrayQuick(newArray);
            }
        }


        const copyFromAndToTime = (index, key) => {
            console.log(key)
            // const newArray = [...weekNameArray];
            const newArray = [...weekNameArray];
            const index_key = newArray.findIndex(item => item.key === key);

            if (index_key !== -1) {
                const newArray = weekNameArray.map(obj => ({
                    ...obj,
                    ['from_value']: weekNameArray[index_key].from_value,
                    ['to_value']: weekNameArray[index_key].to_value,
                    ['checked']: true
                }));
                setWeekNameArray(newArray);
            }
        }

        const [validated, setValidated] = useState(false);
        const onFinalSubmit = (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) { event.preventDefault(); event.stopPropagation(); }
            else {
                setquickFormData2((prevFormData) => ({ ...prevFormData, ['availability']: weekNameArrayQuick }));
                setquickFormData2((prevFormData) => ({ ...prevFormData, ['i_am_fresher']: iAmFresher }));
                setStep(11);
                setShouldRunEffect(true);
            }
            setValidated(true);
        };


        return (
            <div className="staffpop">
                <div className="staffbanner staffbannernew text-center">
                    <h3  ><span className='text-white cursor_pointer' onClick={setStep9} ><i className="fa fa-chevron-left" aria-hidden="true"></i></span> Quick Search</h3>
                    <img src="/front/images/staffserach.png"></img>
                </div>
                <div className="stafformwrap commonform stepscount">
                    <Form noValidate validated={validated} onSubmit={onFinalSubmit} >
                        <div className="row">

                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <h3>Step 5 of 6</h3>
                                </div>
                            </div>

                            {/* <input type="time" name={`availability`} ></input> */}

                            <div className="col-md-12 mt-2">
                                <div className="commonchecbox d-flex">
                                    <span className="agrrimentbox">
                                        <input type="checkbox" checked={iAmFresher} id="i_am_fresher"
                                            name="i_am_fresher"
                                            onChange={(event) => setIAmFresher(event.target.checked)}
                                        />
                                        {/* <input type="checkbox" id="i_am_fresher" name="i_am_fresher" /> */}
                                        <span className="checkafter"></span>
                                    </span>
                                    <label className="checklabel f-w-medium" for="i_am_fresher" >Freshers can also apply</label>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group mb-3">
                                    <label className="form-label">Availability to work</label><label className="smalllbl">Choose days and time you want seeker to be available</label>
                                    {

                                        weekNameArrayQuick.map((item, index) => (
                                            <div className="availability-sec d-flex align-items-center  mb-2" key={item.name}>
                                                <div className="checkboxwraper">
                                                    <input type="checkbox" name={`availability[${item.key}][checkebox][]`} onChange={(e) => checkValueofDay(item.key, 'checkbox', e.target.checked, index)} value={item.from_value} checked={item.checked} ></input>
                                                    <span className="checkspan"></span>
                                                </div>
                                                <p>{item.name}</p>
                                                <input type="time" name={`availability[${item.key}][from][]`} onChange={(e) => checkValueofDay(item.key, 'from', e.target.value, index)} value={item.from_value} ref={(el) => (inputRefs.current[index] = el)} className={`form-control ${activeIndex === index ? 'availibility_active_from' : ''}`} placeholder="00:00 am" ></input>
                                                {/* <input type="time" name={`availability[${item.key}][from][]`} onChange={(e) => checkValueofDay(item.key, 'from', e.target.value, index)} value={item.from_value} className="form-control" placeholder="00:00 am" ></input> */}
                                                <div className="subheadpera">To</div>
                                                <input type="time" name={`availability[${item.key}][to][]`} onChange={(e) => checkValueofDay(item.key, 'to', e.target.value, index)} value={item.to_value} ref={(el) => (inputRefs2.current[index] = el)} className={`form-control ${activeIndex === index ? 'availibility_active_from' : ''}`} placeholder="00:00 am"></input>
                                                {
                                                    (item.from_value && item.to_value) ?
                                                        <>
                                                            <span className="copyin ml-3 cursor_pointer infowrap" onClick={(e) => copyFromAndToTime(index, item.key)}><img src="/front/images/copuicon.png" /><span className="onhoverinfo">Duplicate the same time for week availability</span></span>
                                                        </>
                                                        : ''
                                                }
                                            </div>
                                        ))
                                    }


                                </div>
                            </div>
                            <div className="col-md-12"><button type="submit" className="batn batn-purple w-100"  >Next</button></div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }

    const Findstaff_form_11 = () => {
        const description = quickFormData2.job_descriptions;
        const [showFullDescription, setShowFullDescription] = useState(false);
        // Split the description into words and select the first 20 words
        const limitedDescription = (description) ? description.split(' ').slice(0, 20).join(' ') : '';
        const handleReadMoreClick = () => { setShowFullDescription(!showFullDescription); };

        return (
            <div className="staffpop">
                <div className="staffbanner staffbannernew text-center">
                    {jobidarray_switch.actiontype}
                    <h3>
                        {(jobidarray_switch.screentype == 'new_form' || jobidarray_switch.actiontype != 'preview') && <span className='text-white cursor_pointer' onClick={setStep10} ><i className="fa fa-chevron-left" aria-hidden="true"></i></span>}
                        Quick Search
                    </h3>
                    <img src="/front/images/staffserach.png"></img>
                </div>
                <div className="stafformwrap commonform stepscount">
                    <div className="row">

                        <div className="col-md-12 mb-3">
                            <div className="form-group">
                                <h3 className="d-flex justify-content-between">
                                    Preview
                                    {jobidarray_switch.actiontype != 'preview' && <div className="editbtn" onClick={setStep6}><img src="/front/images/edit.png" /></div>}
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-12 mb-2">
                            <div className="editblock">
                                <label>Job Title</label>
                                <div className="editblockinner d-flex align-items-start">
                                    <h4>{getJobTitleValue(quickFormData2.job_title)}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-2">
                            <div className="editblock">
                                <label>Industry</label>
                                <div className="editblockinner d-flex align-items-start">
                                    {/* <h4>{quickFormData2.industry}</h4> */}
                                    <h4>{getIndustryValue(quickFormData2.industry)}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-2">
                            <div className="editblock">
                                <label>Total experience needed</label>
                                <div className="editblockinner d-flex align-items-start">
                                    <h4>{(quickFormData2.total_experience_month ? quickFormData2.total_experience_month : 0) + 'Year' + ((quickFormData2.total_experience_month > 0) ? 's' : '') + ' - ' + quickFormData2.total_experience_year + ' months'}</h4>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mb-2">
                            <div className="editblock">
                                <label>How many staff looking for</label>
                                <div className="editblockinner d-flex align-items-start">
                                    <h4>{quickFormData2.staff_number}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-2">
                            <div className="editblock joblocationblock">
                                <label>Work location</label>
                                <div className="editblockinner d-flex align-items-center">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="singletag d-flex">
                                            <span>{quickFormData2.work_location}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-2">
                            <div className="editblock">
                                <label>Range from location</label>
                                <div className="editblockinner d-flex align-items-start">
                                    <h4>{quickFormData2.range_from_location}</h4>
                                </div>
                            </div>
                        </div>
                       
                       
                        <div className="col-md-12 mb-2">
                            <div className="editblock">
                                <label>Start date</label>
                                <div className="editblockinner d-flex align-items-start">
                                    <h4>{quickFormData2.start_date_text ? quickFormData2.start_date_text : quickFormData2.start_date}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-2">
                            <div className="editblock">
                                <label>Job end date</label>
                                <div className="editblockinner d-flex align-items-start">
                                    <h4>{quickFormData2.expire_date_text ? quickFormData2.expire_date_text : quickFormData2.expire_date}</h4>
                                </div>
                            </div>
                        </div>




                        <div className="col-md-12 mb-2">
                            <div className="editblock">
                                <label>Salary type</label>
                                <div className="editblockinner d-flex align-items-start">
                                    <h4 className='text-capitalize'>{quickFormData2.salary_type ? quickFormData2.salary_type : 'Hourly'}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-2">
                            <div className="editblock">
                                <label>Salary you are offering</label>
                                <div className="editblockinner d-flex align-items-start">

                                    {
                                        quickFormData2.salary_type == 'hourly' ?
                                            <><h4>${quickFormData2.salary_minimum} to  ${quickFormData2.salary_maximum}</h4> </>
                                            :
                                            <><h4>${quickFormData2.salary_you_are_offering}</h4></>
                                    }

                                </div>
                            </div>
                        </div>


                        <div className="col-md-12 mb-2">
                            <div className="editblock">
                                <label>Job description</label>
                                <div className="editblockinner d-flex align-items-start">
                                    <h4>

                                        {
                                            description.length < 125
                                                ?
                                                description
                                                :
                                                showFullDescription ? (
                                                    <p>
                                                        {description}
                                                        <br></br>
                                                        <a href="#" className='text_safron' onClick={handleReadMoreClick}> hide</a>
                                                    </p>
                                                ) : (
                                                    <p>
                                                        {limitedDescription}
                                                        <br></br>
                                                        <a href="#" className='text_safron' onClick={handleReadMoreClick}> read more...</a>
                                                    </p>
                                                )

                                        }
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-2">
                            <div className="editblock">
                                <label>Requirement</label>
                                <div className="editblockinner d-flex align-items-start">
                                    <h4>{quickFormData2.requirement}</h4>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mb-2">
                            <div className="editblock">
                                <label>Required Tax type</label>
                                <div className="editblockinner d-flex align-items-start">
                                    <h4 className='text-uppercase'>{quickFormData2.required_tax_type}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-2">
                            <div className="editblock">
                                <label>Freshers can also apply</label>
                                <div className="editblockinner d-flex align-items-start">
                                    <h4 className='text-capitalize'>{quickFormData2.i_am_fresher ? 'Yes' : 'No'}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-4">
                            <div className="editblock staffavailability">
                                <label>Availability to work</label>
                                <div className="editblockinner d-flex align-items-start">
                                    <h4>
                                        {
                                            quickFormData2.availability.map((item, index) => (
                                                <div>
                                                    <span>{item.name}:</span>
                                                    <span className="text-underline1"> {item.from_value ? item.from_value + ' am' : '....'} </span>
                                                    <span>To</span>
                                                    <span className="text-underline1"> {item.to_value ? item.to_value + ' pm' : '....'} </span>
                                                </div>
                                            ))
                                        }
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 d-flex">
                                    <button onClick={() => { quickFormDataSubmitFun2(1) }} className="batn batn-purple w-100" >
                                        Search Now
                                    </button>
                                    <span className="inforpop">
                                        <span className="infoicon"><img src="/front/images/infoicoin.png" /></span>
                                        <span className="infodtl">
                                            <h4>Quick Search</h4>
                                            <p>Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Cras dapibus. Nam commodo suscipit quam.</p>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )
    }




    /***********************error modal *****************************/

    const purchaseCoins = () => {
        dispatch(recdeshjobactioncheck({ id: 'close', actiontype: 'preview', screentype: 'new_form', searchtype: 'manually' }))
        //navigate('/recruiter/coinpurchase');
        window.location.replace("/recruiter/coinpurchase");
    }

    const PAYMENT_ERROR_MODAL = () => {
        return (
            <>
                <div class="staffbanner text-center">
                    <h3>
                        <span class="text-white cursor_pointer" onClick={(e) => setStep(1)} >
                            <i class="fa fa-chevron-left" aria-hidden="true"></i>
                        </span>

                    </h3>
                    <img src="/front/images/staffserach.png" />
                </div>
                <div className="stafformwrap commonform stepscount">
                    <div className="form-group text-center mb-3"><h3>You have no sufficient coins </h3></div>
                    <div className='row'>
                        <div className="col-md-12">

                            <div className="row">
                                <div className="col-md-12 d-flex">
                                    <span onClick={(e) => purchaseCoins()} class="batn batn-purple w-100" href="/recruiter/dashboard">Purchase Coin</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }

    return (
        <>
            {/* <ToastContainer />*/}
            {<div className="custom_modal_for_form">

                <LoadScript googleMapsApiKey={GAPI} libraries={libraries}  >
                    {/* <Modal show={isfindstaffModalShow} onHide={() => dispatch(fsmodelhideandseek('close'))}  > */}
                    {/* <Modal show={isfindstaffModalShow} onHide={() => dispatch(recdeshjobactioncheck({ id: 'close', actiontype: 'preview', screentype: 'new_form', searchtype: 'manually' }))}  > */}
                    <Modal show={isfindstaffModalShow}   >

                        {/* <div className="modal-dialog findastaffmodle"> */}
                        <div className="modal-content1">
                            <div className="modal-body1">
                                <button type="button" className="btn-close" onClick={() => dispatch(recdeshjobactioncheck({ id: 'close', actiontype: 'preview', screentype: 'new_form', searchtype: 'manually' }))} data-bs-dismiss="modal" aria-label="Close"></button>

                                {
                                    !isDataLoading ?
                                        <>
                                            {step === 1 && <Findstaff_form_1 />}
                                            {step === 2 && <Findstaff_form_2 />}
                                            {step === 3 && <Findstaff_form_3 />}
                                            {step === 4 && <Findstaff_form_4 />}
                                            {step === 5 && <Findstaff_form_5 />}
                                            {step === 6 && <Findstaff_form_6 />}
                                            {step === 7 && <Findstaff_form_7 />}
                                            {step === 8 && <Findstaff_form_8 />}
                                            {step === 9 && <Findstaff_form_9 />}
                                            {step === 10 && <Findstaff_form_10 />}
                                            {step === 11 && <Findstaff_form_11 />}
                                            {step === 'error' && <PAYMENT_ERROR_MODAL />}
                                        </>
                                        :
                                        <>
                                            <div className='loader_container_fsmodel'>
                                                <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                            </div>
                                        </>
                                }

                            </div>
                        </div>
                        {/* </div> */}
                    </Modal>
                </LoadScript>
            </div>}
        </>
    );
}
export default FindStaffModel;