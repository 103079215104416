import React, { useState, useEffect, Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import ProfileBanner from '../profile_header';
import ProfileSidebar from '../profile_sidebar';

import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux'
import { companyDetailsValidation } from '../../redux_state/reducres/checkDocumentstatus';
import GoogleAutocomplete from '../../expocomp/GoogleAutocomplate';

const CompanyDetail = () => {
   const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
   const dispatch = useDispatch();
   const [varifyDocumentList, setVarifyDocumentList] = useState([]);

   const [subRubSggestions, setSubRubSggestions] = useState([])
   const [subrubValue, setSubrubValue] = useState('');
   const setSubrubsIteminArray = (value) => { setSubrubValue(value); if (value.length > 2) { fetchSuggestions(value); } else { setSubRubSggestions([]) } };
   const getSubrubByli = (value) => { setSubrubValue(value); setSubRubSggestions([]) };

   // Function to fetch suggestions from the API
   const fetchSuggestions = async (value) => {
      try {
         const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_all_subrubs_name/${value}?responsetype=autocomplete`);
         const data = response.data;
         setSubRubSggestions(data);
      } catch (error) { console.error(error); }
   };



   const [isHidden, setIsHidden] = useState(true);
   const toggleHidden = () => { setIsHidden(!isHidden); };

   //const [isHiddenDoc, setIsHiddenDoc] = useState(true);
   //const toggleHiddenDoc = () => { setIsHiddenDoc(!isHiddenDoc); };

   const [isHidden2, setIsHidden2] = useState(true);
   const toggleHidden2 = () => { setIsHidden2(!isHidden2); };

   // const [isHiddenDoc2, setIsHiddenDoc2] = useState(true);
   //const toggleHiddenDoc2 = () => { setIsHiddenDoc2(!isHiddenDoc2); };


   const [companyRegistrationDocumentContents, setCompanyRegistrationDocumentContents] = useState([]);
   const [companyAddressDocumentContents, setCompanyAddressDocumentContent] = useState([]);
   const [getAllStates, setGetAllStates] = useState([]);
   const [getAllIndustry, setGetAllIndustry] = useState([]);

   const [companyDetails, setCompanyDetails] = useState({
      name: '',
      website: '',
      team_size: '',
      industry: '',
      full_address: '',
      address_unit_no: '',
      address_building_no: '',
      address_street_name: '',
      address_suburbs: '',
      address_user_state: '',
      address_document_type: '',
   });


   //const [companyRegistrationDocumentContents , setCompanyAddressDocument] = useState([]);
   const [isAccVerified, setIsAccVerified] = useState('false');
   const [isAccVerified2, setIsAccVerified2] = useState('false');
   const getUserDetailsFun = (userid) => {
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userid); if (resData.data.status == 1) {
               setCompanyRegistrationDocumentContents(resData.data.data.results.company_registration_document);
               setCompanyAddressDocumentContent(resData.data.data.results.company_address_document);
               //console.log(resData.data.data.results.verifications_status.statustype);
               setIsAccVerified(resData.data.data.results.verifications_status.statustype.company_details);
               setIsAccVerified2(resData.data.data.results.verifications_status.statustype.company_address);
            }
         } catch (error) { console.log(error); }
      })();
   }

   const getComapnyDetails = () => {
      const userid = userdetails.unique_id;
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_company_details_by_userid/" + userid);
            if (resData.data.status == 1) {
               setCompanyDetails(resData.data.data.results)
            }
         } catch (error) { console.log(error); }
      })();
   }

   const setCompanyDetailsFun = (event) => {
      const { name, value } = event.target;
      if (name === 'address_unit_no' && (!/^\d*$/.test(value))) {
         return; // Ignore non-numeric input
      }

      setCompanyDetails((prevFormData) => ({
         ...prevFormData,
         [name]: value
      }));
   };

   useEffect(() => {
      const userid = userdetails.unique_id;
      getUserDetailsFun(userid);
      getComapnyDetails();

      const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
      (async () => {

         if (sessionStorageData) {
            setGetAllStates(sessionStorageData.get_all_states);
            setVarifyDocumentList(sessionStorageData.get_all_address_type);
            setGetAllIndustry(sessionStorageData.get_all_industry);
         } else {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_address_type");
               if (resData.data.status == 1) { setVarifyDocumentList(resData.data.data.results) }
            } catch (error) { console.log(error); }

            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_states");
               if (resData.data.status == 1) { setGetAllStates(resData.data.data.results); }
            } catch (error) { console.log(error); }

            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_industry");
               if (resData.data.status == 1) { setGetAllIndustry(resData.data.data.results) }
            } catch (error) { console.log(error); }
         }

      })();

   }, []);

   const [validated, setValidated] = useState(false);
   //const [validatedCnClass, setValidatedCnClass] = useState(false);
   const onCompanyDetailsSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault(); event.stopPropagation();
      } else {
         try {
            const formData = new FormData();
            formData.append('name', companyDetails.name);
            formData.append('website', companyDetails.website);
            formData.append('team_size', companyDetails.team_size);
            formData.append('industry', companyDetails.industry);
            formData.append('user_id', userdetails.unique_id);
            const response = await axios.post(
               `https://squadgoo.com.au/api.squadgoo.com.au/company_address_field_update`,
               formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            if (response.data.status == 1) {
               //getUserDetailsFun(userid); 
               getComapnyDetails();
               setIsHidden(true);
               toast.success(response.data.message);
               if (userdetails.validationdata_check.companydetails_validation == 0) {
                  /*redux validation update */
                  dispatch(companyDetailsValidation(1));
                  /*redux validation check end  */
               };
            } else { toast.warning(response.data.message); }
         } catch (error) { console.error('Error uploading file:', error); }
      }
      setValidated(true);
   };

   const onAddressDetailsSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault(); event.stopPropagation();
      } else {
         try {
            const formData = new FormData();
            formData.append('full_address', event.target.full_address.value);
            formData.append('address_unit_no', event.target.address_unit_no.value);
            formData.append('address_building_no', event.target.address_building_no.value);
            formData.append('address_street_name', event.target.address_street_name.value);
            formData.append('address_suburbs', event.target.address_subrub.value);
            formData.append('address_state', event.target.address_user_state.value);
            formData.append('address_document_type', event.target.address_document_proof_type.value);
            formData.append('user_id', userdetails.unique_id);
            const response = await axios.post(
               `https://squadgoo.com.au/api.squadgoo.com.au/company_address_field_update`,
               formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            if (response.data.status == 1) {
               getComapnyDetails();
               setIsHidden2(true);
               toast.success(response.data.message);
            } else { toast.warning(response.data.message); }
         } catch (error) { console.error('Error uploading file:', error); }
      }
      setValidated(true);
   };


   /*----------------------google address--------------------------*/
   const [address, setAddress] = useState({
      lat: '',
      lng: '',
      unit: '',
      houseNumber: '',
      streetName: '',
      suburb: '',
      city: '',
      state: '',
      country: '',
      placevalue: ''
   });

   const [googleAddressValue, setGoogleAddress] = useState(userdetails.full_address);
   const googleAuotoCompelte = (newAddress) => {
      setAddress(newAddress);
      setGoogleAddress(`${newAddress.placevalue}`)
   };
   const handleInputChange = (e) => { setGoogleAddress(e.target.value); };

   useEffect(() => {
      // console.log(address);
      if (address && address.houseNumber) {
         setCompanyDetails((prevFormData) => ({ ...prevFormData, ['address_building_no']: address.houseNumber }));
      }
      if (address && address.state) {
         const stateObject = getAllStates.find((obj) => obj.state === address.state);
         if (stateObject) {
            setCompanyDetails((prevFormData) => ({ ...prevFormData, ['address_user_state']: stateObject.id }));
         }
      }

      if (address && address.streetName) {
         setCompanyDetails((prevFormData) => ({ ...prevFormData, ['address_street_name']: address.streetName }));
      }

      setCompanyDetails((prevFormData) => ({ ...prevFormData, ['address_unit_no']: address.unit }));


      if (address && address.city) {
         setSubrubValue(address.city);
      }

   }, [address]);


   return (
      <>
         <div className="dashboarwrap">
            <ProfileBanner />
            <div className="jobbtmsec py-5 admininfo">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 profilesidebarwrap">
                        <ProfileSidebar />
                     </div>
                     <div className="col-md-9">
                        <div className="profilerightwraper stepscont commonform basicdetail">

                           <div className="card-box work-experiencesec commonform">
                              <div className="d-flex align-items-center justify-content-between">
                                 <h3 className="mb-3">
                                    Company details
                                    {
                                       isAccVerified == true ?
                                          <><img className='veri_unveri_icon' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="verified"></img></> :
                                          isAccVerified == false ?
                                             <><img className='veri_unveri_icon' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></> :
                                             <></>
                                    }
                                 </h3>
                                 {
                                    isAccVerified == false && <div className="editbtn" onClick={toggleHidden} ><img src="/front/images/edit.png" /></div>
                                 }
                              </div>
                              <div className="editblock">
                                 <div className="editblockinner d-flex align-items-start">
                                    <h4>
                                       <div><span className="mr-2 w-40 text-capitalize">Company name :</span><span className='text-capitalize'>{companyDetails.name ? companyDetails.name : '.....'}</span></div>
                                       <div><span className="mr-2 w-40">Company website :</span><span>{companyDetails.website ? <><a target='_blank' href={companyDetails.website}>{companyDetails.website}</a></> : '.....'}</span></div>
                                       <div><span className="mr-2 w-40">Company size :</span><span>{companyDetails.team_size ? companyDetails.team_size + ' Members' : '1 Member'}</span></div>
                                       <div><span className="mr-2 w-40 text-capitalize">Industry :</span><span className='text-capitalize'>{companyDetails.industry_name ? companyDetails.industry_name : '.....'}</span></div>
 
                                    </h4>

                                 </div>
                                 {!isHidden && <div className="editblockinnerbtm">
                                    <Form noValidate validated={validated} onSubmit={onCompanyDetailsSubmit} >

                                       <div className="row">
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="form-label">Company name <span className="starr">*</span></label>
                                                <input required={true} onChange={setCompanyDetailsFun} name="name" value={companyDetails.name} type="text" className="form-control" placeholder="Enter company name" />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="form-label">Company website <span className="starr">*</span></label>
                                                <input required={true} onChange={setCompanyDetailsFun} name="website" value={companyDetails.website} type="text" className="form-control" placeholder="Enter companyn website" />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="form-label">Company size <span className="starr">*</span></label>
                                                <input required={true} onChange={setCompanyDetailsFun} name="team_size" value={companyDetails.team_size} type="number" min="2" className="form-control" placeholder="Enter Team Size" />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="form-group">
                                                <label className="form-label">Industry <span className="starr">*</span></label>
                                                <select className="form-control" required={true} name="industry" onChange={setCompanyDetailsFun}  >
                                                   <option value="">Select industry</option>

                                                   {
                                                      getAllIndustry.length ?
                                                         <>
                                                            {
                                                               getAllIndustry.map((item, index) => (
                                                                  <>
                                                                     <option selected={companyDetails.industry == item.id ? true : false} value={item.id}>{item.industry}</option>
                                                                  </>
                                                               ))
                                                            }
                                                         </>
                                                         : ''
                                                   }
                                                </select>
                                             </div>
                                          </div>
                                          <div className="col-md-12 mt-3">
                                             <button type="submit" className="batn batn-rounded rounded-orange mr-2">Save</button>
                                             <button className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                          </div>
                                       </div>

                                    </Form>
                                 </div>}
                              </div>
                           </div>

                           {
                              (userdetails.user_type_role == 'rec_owners' || userdetails.user_type_role == 'rec_reprasentative') && <>
                                 <div className="card-box work-experiencesec commonform mt-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                       <h3 className="mb-3">
                                          Company address
                                          {
                                             isAccVerified2 == true ?
                                                <><img className='veri_unveri_icon' src="/front/images/vfy.png"></img></> :
                                                isAccVerified2 == false ?
                                                   <><img className='veri_unveri_icon' src="/front/images/uvfy.png"></img></> :
                                                   <></>
                                          }
                                       </h3>
                                       {
                                          isAccVerified2 == false && <div className="editbtn" onClick={toggleHidden2} ><img src="/front/images/edit.png" /></div>
                                       }
                                    </div>


                                    <div className="editblock">
                                       <div className="editblockinner d-flex align-items-start">
                                          <h4>
                                             <div style={{ width: '100%' }}>
                                                <span className="mr-2 firstcoldtl">Full Address :</span>
                                                <span className='text-capitalize' >{googleAddressValue ? googleAddressValue : companyDetails.full_address ? companyDetails.full_address : '.......'}</span>
                                             </div>
                                          </h4>
                                       </div>
                                       <div className="editblockinner d-flex align-items-start">
                                          <h4>
                                             <div><span className="mr-2 w-40">Unit :</span><span >{companyDetails.address_unit_no ? companyDetails.address_unit_no : '.....'}</span></div>
                                             <div><span className="mr-2 w-40">Building no :</span><span>{companyDetails.address_building_no ? companyDetails.address_building_no : '.....'}</span></div>
                                             <div><span className="mr-2 w-40">Street name :</span><span className='text-capitalize'>{companyDetails.address_street_name ? companyDetails.address_street_name : '.....'}</span></div>
                                             <div><span className="mr-2 w-40">Suburb :</span><span>{companyDetails.address_suburbs ? companyDetails.address_suburbs : '.....'}</span></div>
                                             <div><span className="mr-2 w-40">State:</span><span className='text-capitalize'>{companyDetails.state_name ? companyDetails.state_name : '.....'}</span></div>
                                             <div><span className="mr-2 w-40">Document Type:</span><span className='text-capitalize'>{companyDetails.address_document_proof_type_name ? companyDetails.address_document_proof_type_name : '.....'}</span></div>
                                          </h4>
                                       </div>
                                       {!isHidden2 && <div className="editblockinnerbtm">
                                          <Form noValidate validated={validated} onSubmit={onAddressDetailsSubmit} >
                                             <div className="row">

                                                <div className="col-md-12">
                                                   <div className="d-flex ">
                                                      <div className="form-group mr-2 w-100">
                                                         <label className="form-label">Full address <span className="starr">*</span></label>
                                                         <GoogleAutocomplete
                                                            onSelect={googleAuotoCompelte} required
                                                            name='full_address'
                                                            value={googleAddressValue}
                                                            // value={googleAddressValue}
                                                            placeHolder='Enter full address'
                                                            className="form-control"
                                                            onChange={handleInputChange}
                                                         />
                                                      </div>
                                                   </div>
                                                </div>

                                                <div className="col-md-6">
                                                   <div className="d-flex">
                                                      <div className="form-group mb-2 mr-2">
                                                         <label className="form-label">Unit no<span className="starr">*</span></label>
                                                         <input style={{ width: '200px' }} name="address_unit_no" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_unit_no} type="text" className="form-control" placeholder="Unit" />
                                                      </div>
                                                      <div className="form-group">
                                                         <label className="form-label">Building no<span className="starr">*</span></label>
                                                         <input style={{ width: '200px' }} name="address_building_no" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_building_no} type="text" className="form-control" placeholder="Building no" />
                                                      </div>
                                                   </div>
                                                </div>

                                                <div className="col-md-6">
                                                   <div className="form-group">
                                                      <label className="form-label">Street name<span className="starr">*</span></label>
                                                      <input name="address_street_name" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_street_name} type="text" className="form-control" placeholder="Enter Street name" />
                                                   </div>
                                                </div>
                                                <div className="col-md-6">
                                                   <div className="form-group">
                                                      <label className="form-label">Suburb<span className="starr">*</span></label>
                                                      <input type="text" className="form-control" required name='address_subrub' value={subrubValue} placeholder="Enter your suburb" onChange={(e) => setSubrubsIteminArray(e.target.value)} />
                                                      {
                                                         subRubSggestions.length ?
                                                            <>
                                                               <ul className='auto_search_ul'>
                                                                  {
                                                                     subRubSggestions.map((suggestion) => (<li onClick={(e) => getSubrubByli(suggestion.name)} key={suggestion.id}>{suggestion.name}</li>))
                                                                  }
                                                               </ul>
                                                            </>
                                                            :
                                                            ''
                                                      }
                                                   </div>
                                                </div>
                                                <div className="col-md-6">
                                                   <div className="form-group">
                                                      <label className="form-label">State<span className="starr">*</span></label>
                                                      <select className="form-control mr-2" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_user_state} name="address_user_state" >
                                                         {getAllStates.map((item, index) => (<option value={item.id} >{item.state}</option>))}
                                                      </select>
                                                   </div>
                                                </div>

                                                <div className="col-md-12">
                                                   <div className="form-group">
                                                      <label className="form-label">Document for address proof <span className="starr">*</span> <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>
                                                      <select disabled={(companyAddressDocumentContents.length >= 1 && companyDetails.address_document_type) ? true : false} className="form-control" required={true} onChange={setCompanyDetailsFun} value={companyDetails.address_document_proof_type} name="address_document_proof_type" >
                                                         <option value='' >Select document</option>
                                                         {
                                                            varifyDocumentList.map((item, index) => (
                                                               <option selected={companyDetails.address_document_type == item.document_id ? true : false} value={item.document_id}>{item.document_type}</option>
                                                            ))
                                                         }
                                                      </select>
                                                   </div>
                                                </div>



                                                <div className="col-md-12  mt-3">
                                                   <button type="submit" className="batn batn-rounded rounded-orange mr-2">Save</button>
                                                   <button className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                                </div>
                                             </div>
                                          </Form>
                                       </div>
                                       }
                                    </div>
                                 </div>
                              </>
                           }





                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>






      </>
   );
}
export default CompanyDetail;