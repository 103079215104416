import React, { useState, useEffect } from 'react';
import {  useGoogleLogin } from '@react-oauth/google';


import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

function GmailSingupApp(props) {

    console.log(props.usertype)

    const [ user, setUser ] = useState([]);
    const gsingupFun = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });
    const navigate = useNavigate();
    useEffect(
        () => {
            if (user && user.access_token) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        //setProfile(res.data);
                        console.log(res.data);
                            const getData =  res.data ;
                            const formData = new FormData();
                            formData.append('signuptype', props.usertype);
                            formData.append('first_name', getData.given_name);
                            formData.append('last_name', getData.family_name);
                            formData.append('email', getData.email);
                            formData.append('signupby', 'gmail_signup');
                            formData.append('password', user.access_token);
                            formData.append('picture', getData.picture);
                            formData.append('referralcode', '');
                            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/signup', formData)
                          .then((response) => {
                            if (response.data.status == 1) {

                                //navigate('/verification?id=' + response.data.user_id);
                                let userUniqueId  =  response.data.user_id ; 
                                if(props.usertype == 'rec_indivisual'){ navigate('/indivisual-accountsetup?id='+userUniqueId);} 
                                else if(props.usertype == 'rec_reprasentative') { navigate('/recruiter-accountsetup?id='+userUniqueId);} 
                                else { navigate('/accountsetup?id='+userUniqueId); }
                            }
                            else {
                                 toast.warning(<div dangerouslySetInnerHTML={{ __html: response.data.message }} />) 
                                //  toast.warning(response.data.message) 

                             }
                          }).catch((error) => { console.error(error); });
                    })
                    .catch((err) => console.log(err));
            }
        },
        [ user ]
    );

 

      

    return (
        <div>
            <button onClick={() => gsingupFun()} className="btn social-login hover-up mb-20"  >
                    <img src="/front/images/icon-google.svg" alt="squadgoo" /><strong>Sign up with Google</strong>
                </button>
        </div>
    )
}
export default GmailSingupApp;